import './app.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter as Router } from 'connected-react-router';
import { connect } from 'react-redux';
import { AuthActions as actions } from '../../actions';
import { history } from '../../helpers';
import RootRoutes from '../../routes';

const basePath = process.env.REACT_APP_BASEPATH || '';

class App extends React.Component {
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        const { getLoggedUser } = this.props;
        getLoggedUser();
    }

    render() {
        return (
            <Router basename={basePath} history={history} spinning>
                <RootRoutes />
            </Router>
        );
    }
}

App.propTypes = {
    getLoggedUser: PropTypes.func.isRequired
};

const mapStateToProps = ({ authentication, router }) => ({
    loggedIn: authentication.loggedIn,
    location: router.location,
});

const mapDispatchToProps = dispatch => ({
    getLoggedUser: () => dispatch(actions.getLoggedUser()),
    logout: () => dispatch(actions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
