import React from 'react';
import { Layout } from 'antd';
import Validate from './validate';
import ProtectedRoutes from '../../components/security';
import { Roles } from '../../models';

const { Content } = Layout;

export const AuthRoutesConfig = [
    {
        path: '/validate/:token?',
        exact: true,
        main: Validate,
        access: Roles.ALL,
    }
];

const PublicRoutes = () => (
    <Layout className="layout">
        <Content>
            <ProtectedRoutes routes={AuthRoutesConfig} />
        </Content>
    </Layout>
);

export default PublicRoutes;
