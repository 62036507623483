// eslint-disable-next-line import/no-cycle
import { FieldRules, FieldTypes } from '../components/form/Field';
import { StateFilterFn, StateOptions } from '../config';

export const Roles = Object.freeze({
    PUBLIC: 'PUBLIC',
    ADMIN: 'ADMIN',
    CLIENT: 'CLIENT',
    ALL: ['PUBLIC', 'ADMIN', 'CLIENT']
});

export const RoleOptions = [
    { value: 1, text: 'Administrator' },
];

export const UserModel = {
    firstName: {
        name: 'firstName',
        displayName: 'First Name',
        placeholder: 'Add First Name',
        type: FieldTypes.INPUT,
        validation: [
            FieldRules.REQUIRED
        ],
    },
    lastName: {
        name: 'lastName',
        displayName: 'Last Name',
        placeholder: 'Add First Name',
        type: FieldTypes.INPUT,
        validation: [
            FieldRules.REQUIRED
        ],
    },
    email: {
        name: 'email',
        displayName: 'Email Address',
        placeholder: 'Add Email',
        validation: [
            FieldRules.REQUIRED,
            FieldRules.EMAIL
        ],
        type: FieldTypes.INPUT,
    },
    phone: {
        name: 'phoneNumber',
        displayName: 'Phone Number',
        placeholder: 'Add Phone Number',
        validation: [
            FieldRules.PHONE,
            FieldRules.REQUIRED
        ],
        type: FieldTypes.INPUT,
    },
    address1: {
        name: 'address1',
        displayName: 'Address Line 1',
        placeholder: 'Add Adress Line 1',
        type: FieldTypes.INPUT,
        validation: [
            FieldRules.REQUIRED
        ],
    },
    address2: {
        name: 'address2',
        displayName: 'Address Line 2',
        placeholder: 'Add Adress Line 2',
        type: FieldTypes.INPUT,
    },
    city: {
        name: 'city',
        displayName: 'City',
        placeholder: 'Add City',
        type: FieldTypes.INPUT,
        validation: [
            FieldRules.REQUIRED
        ],
    },
    state: {
        name: 'state',
        displayName: 'State',
        type: FieldTypes.SELECT,
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
        validation: [
            FieldRules.REQUIRED
        ],
        filterOption: StateFilterFn,
        options: StateOptions
    },
    zip: {
        name: 'zip',
        displayName: 'Zip Code',
        placeholder: 'Add ZIP Code',
        validation: [
            FieldRules.REQUIRED,
            FieldRules.ZIP
        ],
        type: FieldTypes.INPUT,
    },
    password: validationFn => (validationFn ? {
            name: 'password',
            displayName: 'Password',
            validation: [
                FieldRules.REQUIRED,
                FieldRules.PASSWORD,
                validationFn
            ],
        } : {
            displayName: 'Password',
            name: 'password',
            validation: [
                FieldRules.REQUIRED,
            ],
        }
    ),
    confirm: validationFn => ({
        name: 'passwordConfirm',
        displayName: 'Repeat Password',
        validation: [
            FieldRules.REQUIRED,
            FieldRules.PASSWORD,
            validationFn
        ],
    }),
};
