import React from 'react';
import { Layout } from 'antd';

import Home from './home';
import AccessDenied from './accessDenied';
import Contact from './contact';
import About from './about';
import NotFound from './notFound';

import ProtectedRoutes from '../../components/security';
import { Roles } from '../../models';
import { Footer, Header } from '../../containers';

const { Content } = Layout;

export const RootRoutesConfig = [
    {
        path: '/',
        exact: true,
        main: Home,
        access: Roles.ALL,
    },
    {
        path: '/403',
        exact: true,
        main: AccessDenied,
        access: Roles.ALL,
    },
    {
        path: '/contact',
        exact: true,
        main: Contact,
        access: Roles.ALL,
    },
    {
        path: '/about',
        exact: true,
        main: About,
        access: Roles.ALL,
    },
    {
        path: '/*',
        exact: true,
        main: NotFound,
        access: Roles.ALL,
    },

];

const PublicRoutes = () => (
    <Layout className="layout">
        <Header />
        <Content>
            <ProtectedRoutes routes={RootRoutesConfig} />
        </Content>
        <Footer />
    </Layout>
);

export default PublicRoutes;
