import './depop.barcharts.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Tooltip, Axis, StackBar, Coord, Legend } from 'viser-react';
import DataSet from '@antv/data-set';
import * as colors from '../../../assets/_variables.scss';

const RangeTypes = {
    op: { name: 'Optimal', color: colors.seaGreen },
    in: { name: 'In Range', color: colors.brandOne },
    out: { name: 'Out of Range', color: colors.error }
};

const getRange = (name) => {
    const key = Object.keys(RangeTypes)
        .filter(item => RangeTypes[item].name === name)[0];
    return RangeTypes[key];
};

const barsColor = ['range', r => getRange(r).color];

const scale = [
    {
        dataKey: 'type',
    },
    {
        dataKey: 'percent',
        formatter: value => `${value}%`,
        min: 0,
        max: 100,
        ticks: [],
    }
];

const labelX = ['percent*range', (percent, range) => {
    const { color } = getRange(range);
    return {
        position: 'middle',
        offsetX: -10,
        offsetY: 13,
        textStyle: {
            fontSize: 14,
            fontWeight: 600,
            fill: color,
        }
    };
}];

const redistributeTo100 = (source) => {
    const res = source.map(x => Math.round(x));
    let diff = res.reduce((acc, x) => acc + x, 0) - 100;
    while (diff > 0) {
        const idx = res.indexOf(Math.max(...res));
        res[idx] -= 1;
        diff -= 1;
    }
    return res;
};

const DepopBarCharts = ({ data = {}, legend, sensors, labelOnTop, style }) => {    
    const transformedData = {
        stage4: redistributeTo100([
            parseFloat(data.stage4InOptimalRange), 
            parseFloat(data.stage4InAcceptableRange), 
            parseFloat(data.stage4OutOfRange)
        ]),
        stage3: redistributeTo100([
            parseFloat(data.stage3InOptimalRange),
            parseFloat(data.stage3InAcceptableRange),
            parseFloat(data.stage3OutOfRange)
        ]),
        stage2: redistributeTo100([
            parseFloat(data.stage2InOptimalRange),
            parseFloat(data.stage2InAcceptableRange),
            parseFloat(data.stage2OutOfRange)
        ]),
        stage1: redistributeTo100([
            parseFloat(data.stage1InOptimalRange),
            parseFloat(data.stage1InAcceptableRange),
            parseFloat(data.stage1OutOfRange)
        ]),
        stage: redistributeTo100([
            parseFloat(data.inOptimalRange),
            parseFloat(data.inAcceptableRange),
            parseFloat(data.outOfRange)
        ])
    };

    const { stage, stage1, stage2, stage3, stage4 } = transformedData;

    const sourceData = [
        {
            key: 4,
            type: '% in range stage 4',
            [RangeTypes.op.name]: stage4[0],
            [RangeTypes.in.name]: stage4[1],
            [RangeTypes.out.name]: stage4[2]
        },
        {
            key: 3,
            type: '% in range stage 3',
            [RangeTypes.op.name]: stage3[0],
            [RangeTypes.in.name]: stage3[1],
            [RangeTypes.out.name]: stage3[2]
        },
        {
            key: 2,
            type: '% in range stage 2',
            [RangeTypes.op.name]: stage2[0],
            [RangeTypes.in.name]: stage2[1],
            [RangeTypes.out.name]: stage2[3]
        },
        {
            key: 1,
            type: '% in range stage 1',
            [RangeTypes.op.name]: stage1[0],
            [RangeTypes.in.name]: stage1[1],
            [RangeTypes.out.name]: stage1[2]
        },
        {
            key: 0,
            type: '% in range average',
            [RangeTypes.op.name]: stage[0],
            [RangeTypes.in.name]: stage[1],
            [RangeTypes.out.name]: stage[2]
        },
    ];
    
    const sensorsSource = sourceData.filter(item => sensors.includes(item.key));
    const dv = new DataSet.View().source(sensorsSource);

    dv.transform({
        type: 'fold',
        fields: [RangeTypes.op.name, RangeTypes.in.name, RangeTypes.out.name],
        key: 'range',
        value: 'percent',
        retains: ['type'],
    });
    const chartData = dv.rows;

    const padding = labelOnTop ? [30, 25, 20, 25] : [30, 10, 40, 140];
    const labelY = labelOnTop ? { offsetY: -20, offsetX: 120 } : { offsetX: 0, offsetY: 0 };
    const height = sensors.length * (80 + (labelOnTop ? 0 : 40));
    
    return (
        <div className="depop-barcharts" style={style}>
            <Chart
                forceFit
                data={chartData}
                scale={scale}
                height={height}
                padding={padding}
            >
                {legend && (
                    <h3>Legend:</h3>
                )}
                <Coord type="rect" direction="LB" />
                <Tooltip />
                <Axis
                    dataKey="type"
                    position="right"
                    label={labelY}
                />
                {legend && <Legend position="top-left" />}
                <StackBar
                    position="type*percent"
                    color={barsColor}
                    label={labelX}
                    size={24}
                    opacity={1}
                />
            </Chart>
        </div>
    );
};

DepopBarCharts.propTypes = {
    data: PropTypes.object.isRequired,
    legend: PropTypes.bool,
    sensors: PropTypes.array,
    labelOnTop: PropTypes.bool,
    style: PropTypes.object
};

DepopBarCharts.defaultProps = {
    legend: false,
    labelOnTop: false,
    sensors: [0, 1, 2, 3, 4],
    style: {}
};

export default DepopBarCharts;
