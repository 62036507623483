import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Form, Modal, Spin, Tabs } from 'antd';
import { localDate } from '../../../helpers';
import { Item } from '../../../components/form';
import DepopEdit from './depop.edit';
import PlaceholderImg from '../../../assets/empty-placeholder.png';
import { depopStatus } from '../../../models';

const { TabPane } = Tabs;

const DepopData = ({ depop }) => (
    <div>
        <Item label="Barn #">{depop.barn}</Item>
        <Item label="Date">{localDate(depop.startDate)}</Item>
        <Item label="Est. Hens in the Barn">{depop.hens}</Item>
        <Item label="Production System">{depop.productionSystem}</Item>
        <Item label="Depop Type">{depop.type}</Item>
        <Item label="Hen Type / Breed">{depop.henType}</Item>
        <Item label="Weeks of Age">{depop.weeksOfAge}</Item>
    </div>
);

DepopData.propTypes = {
    depop: PropTypes.object.isRequired
};

class DepopPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            depop: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { depop } = props;
        if (depop.id !== state.depop.id) {
            return { depop };
        }
        return null;
    }

    toggleEditModal = visible => this.setState({ visible })

    onDepopChange = (values) => {
        this.setState({ depop: values });
    }

    render() {
        const { view, loading } = this.props;
        const { visible, depop } = this.state;

        return (
            <Spin spinning={loading}>
                <div className={view}>
                    <Form
                        className="readonly"
                        layout="horizontal"
                    >
                        {view === 'preview' ? (
                            <>
                                <div className="depop-detail-header">
                                    <img src={depop.logo || PlaceholderImg} alt="Logo" />
                                    <h1>{depop.farmName}</h1>
                                </div>
                                <Divider />
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="Depop info" key="1">
                                        <DepopData depop={depop} />
                                    </TabPane>
                                    <TabPane tab="Assigned Runs" key="2">
                                        <h1>Empty</h1>
                                    </TabPane>
                                </Tabs>
                            </>
                        ) : (
                            <>
                                <h2>Depop characteristics</h2>
                                <Divider />
                                <DepopData depop={depop} />
                                {(view !== 'certificate' || depop.status !== depopStatus.APPROVED) && (
                                    <>
                                        <Divider />
                                        <Button
                                            type="primary right"
                                            onClick={() => this.toggleEditModal(true)}
                                        >
                                            Edit Info
                                        </Button>

                                        <Modal
                                            title="Edit Depop"
                                            visible={visible}
                                            onCancel={() => this.toggleEditModal(false)}
                                            footer={null}
                                        >
                                            <DepopEdit
                                                depop={depop}
                                                onChange={this.onDepopChange}
                                                onCancel={() => this.toggleEditModal(false)}
                                            />
                                        </Modal>
                                    </>
                                )}
                            </>
                        )}
                    </Form>
                </div>
            </Spin>
        );
    }
}

DepopPreview.propTypes = {
    depop: PropTypes.object.isRequired,
    view: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired
};

export default DepopPreview;
