import './certificate.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Divider, Spin } from 'antd';
import RunChart from '../admin/runs/run.chart';
import { sensorConfig } from '../../models';
import { RunActions as actions } from '../../actions';
import { localDate } from '../../helpers';
import BarCharts from '../admin/runs/run.barcharts';
import RunStatistics from '../admin/runs/run.statistics';
import RunCharacteristics from '../admin/runs/run.characteristics';

const Charts = ({ sensors, loading }) => sensorConfig.map((sensor) => {
    const sensorData = sensors[sensor.id] || { data: [], average: 0, id: sensor.id };
    return (
        <div key={sensor.id}>
            <div className="CO2Titles">
                <h3>
                    Stage {sensor.id + 1}: 
                    <span
                        className={sensorData.inRange}
                    >{sensorData.average}%
                    </span>
                </h3>
            </div>
            <RunChart 
                id={sensor.id}
                data={sensorData.data} 
                loading={loading}
            />
        </div>
    );
});

const RunCertificate = ({ run, Footer, loading, onLoad }) => {
    const gutter = [16, 16];
    const [loadingData, setLoadingData] = useState(true);
    const [sensors, setSensors] = useState([]);
    
    useEffect(() => {
        const { id } = run;
        const load = async () => {
            const data = await actions.getData(id);
            setSensors(data);
            setLoadingData(false);
            onLoad();
        };
        if (id) load();      
    }, [run, onLoad]);
   
    return (
        <div className="run-certificate">
            <Spin spinning={loading}>
                <Row gutter={gutter}>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <h2>Run ID: { run.id }</h2>
                                <h4>Event Date: {localDate(run.finishDate)}</h4>
                            </Col>
                        </Row> 
                    </Col>
                </Row>
                <Row gutter={gutter}>
                    <Col span={12}>
                        <div className="cardc details">
                            <RunCharacteristics {...run} />
                        </div>                 
                    </Col>
                    <Col span={12}>
                        <div className="cardc details">
                            <RunStatistics {...run} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="cardc">
                            <h3>Run performance</h3>
                            <Divider />
                            <Row>
                                <Col span={12} offset={0}>
                                    <BarCharts 
                                        run={run} 
                                        legend={false}
                                        sensors={[1, 2]}
                                        labelOnTop
                                    />
                                </Col>
                                <Col span={12} offset={0}>
                                    <BarCharts 
                                        run={run} 
                                        sensors={[3, 4]}
                                        legend={false}
                                        labelOnTop
                                    />
                                </Col>
                            </Row>
                        </div> 
                    </Col> 
                </Row>
                <Row gutter={gutter}>
                    <Col span={24}>
                        <div className="cardc">
                            <h3>CO2 Chart views</h3>
                            <Divider />
                            <Charts 
                                sensors={sensors} 
                                loading={loadingData} 
                            />
                        </div> 
                    </Col> 
                </Row>
                <Row gutter={gutter}>
                    <Col span={24}>
                        <Footer />
                    </Col>
                </Row> 
            </Spin>   
        </div>
    ); 
};

RunCertificate.propTypes = {
    run: PropTypes.object.isRequired,
    Footer: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onLoad: PropTypes.func.isRequired
};

export default RunCertificate;
