import { ClientConstants as constants } from '../constants';

const initialState = Object.freeze({});

const client = (state = initialState, action) => {
    switch (action.type) {
        case constants.CLIENT_CLEAR: {
            return {
                ...initialState,
            };
        }
        case constants.CLIENT_LOADED: {
            return {
                ...state,
                ...action.order,
                orderLoading: false,
                refresh: state.refresh + 1
            };
        }
        case constants.CLIENT_CREATED: {
            return {
                ...state,
                ...action.client,
            };
        }
        case constants.CLIENT_UPDATED: {
            return {
                ...state,
                ...action.client,
            };
        }
        default:
            return state;
    }
};

export { client };
