import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin, Row, Col, Button, Icon, Divider } from 'antd';
import { Link } from 'react-router-dom';
import RunCharts from './run.charts';

import { RunActions as actions } from '../../../actions';
import { localDate, getQueryStringValue } from '../../../helpers';
import { Card } from '../../../containers';
import RunStatistics from './run.statistics';
import RunCharacteristics from './run.characteristics';
import BarCharts from './run.barcharts';

const RunToolbar = ({ id, clientId }) => (
    <div className="tool-bar">
        <div className="container">
            <Link to={`/runs/preview/${id}${clientId ? `?client=${clientId}` : ''}`}>
                <Button 
                    type="link back"
                > 
                    <Icon type="left-circle" theme="filled" />
                    Back to All Runs
                </Button>
            </Link>
        </div>
    </div>
);

RunToolbar.propTypes = {
    id: PropTypes.number.isRequired,
    clientId: PropTypes.any
};

RunToolbar.defaultProps = {
    clientId: undefined
};

const Run = ({ params: { id } }) => {
    const [run, setRun] = useState({});
    const [loading, setLoading] = useState(true);
    const clientId = getQueryStringValue('client');
        
    useEffect(() => {
        const load = async () => {
            const data = await actions.get(id);
            setRun(data);
            setLoading(false);
        };

        if (id) load();
    }, [id]);
    
    const { farm, finishDate, notes } = run;
    
    return (
        <>  
            <RunToolbar 
                id={run.id} 
                clientId={clientId}
            />
            <div className="container">
                <Row>
                    <Col span={24} className="submenu">
                        <h1 className="right">ID: {id}</h1>
                        <h1>{farm && farm.client && farm.client.name}</h1>
                        <h4>Event date: {localDate(finishDate)}</h4>
                    </Col>
                    <Col span={8}>
                        <Spin spinning={loading}>
                            <Card title="Run Data">
                                <RunCharacteristics {...run} />
                                
                                <h3>Run Performance</h3>
                                <Divider />
                                <BarCharts run={run} legend />
                                
                                <RunStatistics {...run} />
                            </Card>
                            <Card title="Comment">
                                {notes}
                            </Card>
                        </Spin>
                    </Col>
                    <Col span={16}>
                        <Card title="Run Statistics" expandable>
                            <RunCharts id={id} />
                        </Card>
                        <Card title="List of Events" expandable />
                    </Col>
                </Row>
            </div>
        </>
    );
};

Run.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};

export default Run;
