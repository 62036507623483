import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'antd';
import { depopStatus } from '../../../models';
import { Secured } from '../../../components/security';
import { DepopActions as actions } from '../../../actions';
import ApproveButton from './depop.approve';

const { PENDING_APPROVAL, IN_PROGRESS, APPROVED } = depopStatus;

const DepopToolbar = ({ depopId, status, onChange }) => {
    const [loading, setLoading] = useState(false);
    
    if (!status) return null;

    const updateStatus = (newStatus) => {
        setLoading(true);
        actions.update(depopId, { status: newStatus })
            .then(depop => onChange(depop))
            .finally(() => setLoading(false));
    };

    const downloadCertificate = () => {
        setLoading(true);
        actions.certificate(depopId)
            .finally(() => setLoading(false));
    };

    const ButtonComplete = () => (
        <Button
            key="complete"
            loading={loading}
            type="secondary"
            onClick={() => updateStatus(PENDING_APPROVAL)}
        >
            Complete Depop Event
        </Button>
    );

    const ButtonApprove = () => (
        <Secured grant="ADMIN">
            <ApproveButton
                type="secondary"
                id={depopId}
                onApprove={onChange}
            >
                Approve Depop Event
            </ApproveButton>
        </Secured>
    );

    const ButtonReopen = () => (
        <Button
            type="ghost"
            onClick={() => updateStatus(IN_PROGRESS)}
        >
            Reopen Depop Event
        </Button>
    );

    const ButtonCertificate = () => (
        <Button
            type="secondary"
            onClick={downloadCertificate}
            loading={loading}
        >
            View Certificate
        </Button>
    );

    const StatusConfig = {
        [IN_PROGRESS]: {
            icon: 'play-circle',
            title: 'In progress',
            buttons: [<ButtonComplete key="complete" />]
        },
        [PENDING_APPROVAL]: {
            icon: 'clock-circle',
            title: 'Completed, Pending Approval',
            buttons: [
                <ButtonApprove key="approve" />, 
                <ButtonReopen key="reopen" />
            ]
        },
        [APPROVED]: {
            icon: 'check-circle',
            title: 'Approved',
            buttons: [
                <ButtonCertificate key="view" />,
                // <ButtonReopen key="reopen" temp />
            ], 
        }
    };
    
    const thisConfig = StatusConfig[status];
    return (
        <div>
            <div>
                <Icon type={thisConfig.icon} theme="filled" />
                <span>{thisConfig.title}</span>
            </div>
            {thisConfig.buttons}
        </div>
    ); 
};

DepopToolbar.propTypes = {
    depopId: PropTypes.string.isRequired,
    status: PropTypes.number,
    onChange: PropTypes.func.isRequired
};

DepopToolbar.defaultProps = {
    status: undefined
};

export default DepopToolbar;
