import { Upload, Icon, message } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { ClientActions as actions } from '../../../actions';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

export default class Avatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            logo: props.data.logo,
            currLogo: '',
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data.logo !== state.logo) {
            return {
                logo: props.data.logo
            };
        }
        return null;
    }

    handleChange = (info) => {
        const { clientId } = this.props;
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done' || info.file.status === 'error') {
            getBase64(info.file.originFileObj, logo => actions
                .update(clientId, { logo }).then(() => this.setState({ currLogo: logo })));
        }
    };

    render() {
        const { loading, currLogo } = this.state;
        let { logo } = this.state;

        if (currLogo) logo = currLogo;

        const uploadButton = (
            <div>
                <Icon type={loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
            >
                {logo ? <img src={logo} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        );
    }
}

Avatar.propTypes = {
    data: PropTypes.object.isRequired,
    logo: PropTypes.string,
    clientId: PropTypes.string.isRequired,
};

Avatar.defaultProps = {
    logo: '',
};
