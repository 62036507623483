import React, { Component } from 'react';
import { Table, Row, Col, Button, Icon, Modal } from 'antd';
import PropTypes from 'prop-types';
import RouteWithSider from '../sider';
import Card from '../../../containers/card';
import { history, sortFn, getQueryStringValue } from '../../../helpers';
import FormNewDepop from './depop.new';
import { DepopActions as actions } from '../../../actions';
import DepopPreview from './depop.preview';
import { statusIconConst } from '../../../models';
import PlaceholderImg from '../../../assets/empty-placeholder.png';

const renderStatus = (status) => {
    const correct = statusIconConst.find(item => item.value === status);
    return <Icon type={correct.type} theme="filled" />;
};

const renderAction = () => <Icon type="dash" />;

const renderName = (farmName, { logo, name }) => {
    const text = (farmName || 'N/A');
    const img = (logo || PlaceholderImg);
    const data = (
        <div className="name-row">
            <img src={img} alt="Logo" />
            <div className="text"><span className="bold">{name}</span><p>{text}</p></div>
        </div>
    );
    return data;
};

const columns = [
    {
        title: 'Name',
        dataIndex: 'farmName',
        render: renderName,
        sorter: sortFn.string,
    },
    {
        title: 'Depop ID',
        dataIndex: 'id',
        render: value => (value || 'N/A'),
        sorter: sortFn.number('id'),
    },
    {
        title: 'Runs',
        dataIndex: 'runsCount',
        render: value => (value || 0),
        sorter: sortFn.number('runsCount'),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: renderStatus,
        sorter: sortFn.number('status'),
    },
    {
        title: 'Number of Hens',
        dataIndex: 'hens',
        sorter: sortFn.number('hens'),       
        width: 80,
    },
    {
        title: 'Action',
        render: renderAction,
        sorter: sortFn.string,
    },
];

const changeDepop = id => history.push(`/depops/preview/${id}`);
const gotoDetails = id => history.push(`/depops/${id}`);

export class Depops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            visible: false
        };
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        this.clientId = getQueryStringValue('client');
        actions.list(this.clientId)
            .then((data) => {
                this.setState({ data });
            })
            .finally(() => this.setState({ loading: false }));
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    getRowClassName = ({ id }) => {
        const { params: { depopId } } = this.props;
        if (id.toString() === depopId) {
            return 'ant-table-row-selected';
        }
        return null;
    }

    onRow = depop => ({
        onClick: () => changeDepop(depop.id),
        onDoubleClick: () => gotoDetails(depop.id)
    });

    render() {
        const { visible, data, loading } = this.state;
        const { params: { depopId } } = this.props;
        let depop = {};
        if (depopId) {
            depop = Array.filterFirst(data, item => item.id.toString() === depopId) || {};
        }
        return (
            <RouteWithSider>
                <Row>
                    <Col xs={16} className="depops-table">
                        <Button
                            type="link right addNew"
                            onClick={this.showModal}
                            title="Add New Depop"
                        >
                            <Icon type="plus" style={{ color: 'white', fontSize: 20 }} />
                        </Button>
                        <Modal
                            width={700}
                            title="New Depop"
                            visible={visible}
                            onCancel={this.handleCancel}
                            footer={null}
                        >
                            <FormNewDepop onCancel={this.handleCancel} />
                        </Modal>
                        <Card title="Depops">
                            <Table
                                loading={loading}
                                rowClassName={this.getRowClassName}
                                rowKey="id"
                                columns={columns}
                                dataSource={data}
                                onRow={this.onRow}
                            />
                        </Card>
                    </Col>

                    {depopId
                        && (
                            <Col xs={8} className="card-depop">
                                <Card title="Depop Details">
                                    <DepopPreview
                                        loading={loading}
                                        depop={depop}
                                        view="preview"
                                    />
                                    <Button
                                        type="primary right"
                                        onClick={() => gotoDetails(depopId)}
                                    >
                                        View Depop
                                    </Button>
                                </Card>
                            </Col>
                        )}
                </Row>
            </RouteWithSider>
        );
    }
}

Depops.propTypes = {
    params: PropTypes.object.isRequired
};

export default Depops;
