// eslint-disable-next-line import/no-cycle
import api from './_api';

const Path = '/clients';

const list = clientId => api
    .get(`${Path}/${clientId}/farms`) 
    .then(({ data }) => data);

const get = (clientId, farmId) => api
    .get(`${Path}/${clientId}/farms/${farmId}`);

const update = (clientId, farmId, values) => api
    .put(`${Path}/${clientId}/farms/${farmId}`, values);

const remove = (clientId, farmId) => api
    .delete(`${Path}/${clientId}/farms/${farmId}`);
    
const create = (clientId, data) => api
    .post(`${Path}/${clientId}/farms`, data);

export const FarmService = {
    list,
    get,
    update,
    create,
    remove
};
