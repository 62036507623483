/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

class RadioGroup extends React.Component {
    constructor() {
        super();
        this.state = { value: undefined };
    }

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            let { value } = nextProps;
            if (Object.isObject(value) && value.value) {
                value = value.value;
            }
            return { value };
        }
        return null;
    }

    triggerChange = (e) => {
        const { value } = e.target;
        const { onChange } = this.props;

        if (onChange) {
            onChange(value);
        }
    }

    render() {
        const { value } = this.state;
        const { options } = this.props;

        return (
            <Radio.Group
                onChange={this.triggerChange}
                value={value}
            >
                {options.map((item, index) => (
                    <Radio
                        key={index}
                        value={item.value}
                    >
                        {/* eslint-disable-next-line max-len */}
                        {item.tooltip ? (<span><b>{item.text}</b>{item.tooltip}</span>) : <b>{item.text}</b>}
                    </Radio>
                ))}
            </Radio.Group>
        );
    }
}

RadioGroup.propTypes = {
    // eslint-disable-next-line react/require-default-props
    value: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array
};

RadioGroup.defaultProps = {
    onChange: () => {
    },
    options: {}
};

export { RadioGroup };
