import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import ProtectedRoute from './route';
import { history } from '../../helpers';

const basePath = process.env.REACT_APP_BASEPATH || '';

const ProtectedRoutes = (props) => {
    const {
        routes,
        user,
        loggedIn,
        loggingIn
    } = props;
    return (
        <Router basename={basePath} history={history} spinning>
            <Switch>
                {routes.map(route => (
                    <ProtectedRoute
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.main}
                        access={route.access}
                        user={user}
                        loggedIn={loggedIn}
                        loggingIn={loggingIn}
                    />
                ))}
            </Switch>
        </Router>
    );
};

ProtectedRoutes.propTypes = {
    routes: PropTypes.array,
    user: PropTypes.object,
    loggedIn: PropTypes.bool,
    loggingIn: PropTypes.bool,
};

ProtectedRoutes.defaultProps = {
    loggingIn: false,
    loggedIn: false,
    user: {},
    routes: []
};

const mapStateToProps = ({ authentication }) => (
    {
        user: authentication.user,
        loggedIn: authentication.loggedIn,
        loggingIn: authentication.loggingIn,
    }
);

export default connect(mapStateToProps)(ProtectedRoutes);
