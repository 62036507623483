import { notification } from 'antd';
import { handleError } from './helpers';
// eslint-disable-next-line import/no-cycle
import { ClientService as service } from '../services';

const notificationLog = (type, message, description) => {
    notification[type]({
        message,
        duration: 2,
        description
    });
};

const list = () => service
    .list()
    .then(
        data => data,
        handleError
    );

const get = id => service
    .get(id)
    .then(
        data => data,
        handleError
    );

const create = data => service.create(data)
    .then(
        res => res,
        handleError
    );

const update = (id, ...args) => service.update(id, ...args)
    .then(
        (data) => {
            notificationLog('success', 'Saved successfully');
            return data;
        },
        handleError
    );

const getUsersForClient = id => service.getUsersForClient(id)
    .then(
        res => res,
        handleError
    );

const addUserToClient = data => service.addUserToClient(data)
    .then(
        res => res,
        handleError
    );

const deleteUserFromClient = (userId, clientId) =>
    service.deleteUserFromClient(userId, clientId)
        .then(
            res => res,
            handleError
        );

export const ClientActions = {
    list,
    get,
    update,
    create,
    getUsersForClient,
    addUserToClient,
    deleteUserFromClient,
};
