import { handleError } from './helpers';
// eslint-disable-next-line import/no-cycle
import { FarmService as service } from '../services';

const list = (...args) =>
    service.list(...args)
        .then(
            data => data,
            handleError
        );

const get = (clientId, farmId) =>
    service.get(clientId, farmId)
        .then(
            data => data,
            handleError
        );

const create = (clientId, data) =>
    service.create(clientId, data)
        .then(
            res => res,
            handleError
        );

const update = (clientId, farmId, ...args) =>
    service.update(clientId, farmId, ...args)
        .then(
            data => data,
            handleError
        );

const remove = (clientId, farmId) =>
    service.remove(clientId, farmId)
        .then(
            data => data,
            handleError
        );

export const FarmActions = {
    list,
    get,
    update,
    create,
    remove
};
