import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Menu, Popconfirm } from 'antd';
import { depopStatus } from '../../../models';
import { DepopActions as actions, RunActions } from '../../../actions';

export class UnassignedRunsToolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depops: [],
        };
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        actions.list()
            .then(data => data.filter(item => item.status === depopStatus.IN_PROGRESS))
            .then((depops) => {
                this.setState({ depops });
            });
    }

    AssignRuns = (depopId) => {
        actions.assign(depopId, this.props.selectedRuns)
            .then(
                this.props.onSuccess
            );
    }

    DeleteRuns = () => RunActions.remove(this.props.selectedRuns)
        .then(
            this.props.onSuccess
        );

    render() {
        const { depops } = this.state;
        const { selectedRuns } = this.props;
        const disabled = selectedRuns.length === 0;

        const menu = (
            <Menu>
                {depops.map(item => (
                    <Menu.Item key={item.id} onClick={() => this.AssignRuns(item.id)}>
                        {item.name}
                    </Menu.Item>
                ))}
            </Menu>
        );

        return (
            <>
                <Dropdown overlay={menu} trigger={['click']} disabled={disabled}>
                    <Button icon="link" type="link">Assign</Button>
                </Dropdown>
                <Popconfirm
                    onConfirm={() => this.DeleteRuns()}
                    title="Are you sure you want to delete these runs?"
                    okText="Delete"
                    placement="bottom"
                >
                    <Button icon="delete" type="link" disabled={disabled}>Delete</Button>
                </Popconfirm>
            </>
        );
    }
}

UnassignedRunsToolbar.propTypes = {
    selectedRuns: PropTypes.array.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default UnassignedRunsToolbar;
