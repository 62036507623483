import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class SecuredComponent extends React.Component {
    render() {
        const { role, grant, children } = this.props;
        if (role === grant) {
            return children;
        }
        return null;
    }
}

SecuredComponent.propTypes = {
    role: PropTypes.string.isRequired,
    grant: PropTypes.string.isRequired,
    children: PropTypes.any
};

SecuredComponent.defaultProps = {
    children: null
};

const mapStateToProps = ({ authentication: { user: { role } } }) => ({ role });

const Secured = connect(mapStateToProps)(SecuredComponent);
export { Secured };
