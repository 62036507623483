import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Button, Icon, Popconfirm } from 'antd';
import { MachineActions as actions } from '../../../actions';
import { sortFn, renderFn } from '../../../helpers';
import RouteWithSider from '../sider';
import { Card } from '../../../containers';
import MachineNew from './machine.new';
import MachineEdit from './machine.edit';

const MachinesRoute = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visibleNew, setVisibleNew] = useState(false);
    const [machineToEdit, setMachineToEdit] = useState();

    const fetch = async () => {
        const result = await actions.list()
            .finally(() => setLoading(false));
        setData(result);
    };

    const remove = async (id) => {
        await actions.remove(id);
        fetch();
    };

    const handleSuccess = async () => {
        setVisibleNew(false);
        setMachineToEdit(undefined);
        setLoading(true);
        await fetch();
        setLoading(false);
    };

    const handleRow = row => ({
        onDoubleClick: () => setMachineToEdit(row)
    });

    useEffect(() => {     
        fetch();
    }, []);    

    const renderToolbar = (value, row) => (
        <>
            <Button type="link" icon="edit" onClick={() => setMachineToEdit(row)} />
            <Popconfirm
                title="Are you sure to delete this Machine?"
                onConfirm={() => remove(value)}
                okText="Yes"
                cancelText="No"
            >
                <Button type="link" icon="delete" />
            </Popconfirm>
        </>
    );
        
    const columns = [
        {
            title: 'Machine SN',
            dataIndex: 'serialNumber',
            sorter: sortFn.string('serialNumber'),
        },
        {
            title: 'Date added',
            dataIndex: 'createdAt',
            sorter: sortFn.date('createdAt'),
            render: renderFn.date
        },
        {
            title: 'Machine Controller',
            dataIndex: 'machineController.name',
            render: value => value || <i style={{ color: 'lightgray' }}>Unassigned</i>
        },
        {
            title: 'Client',
            dataIndex: 'client.name',
            render: value => value || <i style={{ color: 'lightgray' }}>Unassigned</i>
        },
        {
            title: '',
            dataIndex: 'id',
            render: renderToolbar,
            width: 110
        },
    ];

    return (
        <RouteWithSider>
            <MachineEdit 
                machine={machineToEdit} 
                onSuccess={handleSuccess}
                onCancel={() => setMachineToEdit()} 
            />
            <MachineNew 
                visible={visibleNew}
                onSuccess={handleSuccess}
                onCancel={() => setVisibleNew(false)} 
            />
            <Row>
                <Col xs={16}>
                    <Button
                        type="link right addNew"
                        onClick={() => setVisibleNew(true)}
                        title="Add New Machine"
                    >
                        <Icon type="plus" />
                    </Button>
                    <Card title="Machines">
                        <Table
                            loading={loading}
                            rowKey="id"
                            columns={columns}
                            dataSource={data}
                            onRow={handleRow}
                        />
                    </Card>
                </Col>
            </Row>
        </RouteWithSider>
    );
};

export default MachinesRoute;
