import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ClientActions as actions } from '../../../actions';
import { ClientModel as model } from '../../../models';

import Form, { FormItem } from '../../../components/form';
import { history } from '../../../helpers/history.helpers';

const NewClient = ({ onCancel }) => {
    const handleSubmit = (values) => {
        actions.create({ ...values })
            .then(({ id }) => history.push(`/clients/${id}/details`));
    };
    
    return (
        <Form
            initialValues={{}}
            onSubmit={handleSubmit}
        >
            <FormItem {...model.clientName} />
            <Button
                type="primary right"
                htmlType="submit"

            >Create
            </Button>
            <Button
                type="default right"
                onClick={onCancel}
            >Cancel
            </Button>               
        </Form>
    );
};

NewClient.propTypes = {
    onCancel: PropTypes.func.isRequired,
};

export default NewClient;
