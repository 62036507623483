import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Icon, Row } from 'antd';
import PropTypes from 'prop-types';
import { history } from '../../../helpers';
import { DepopActions as actions } from '../../../actions';
import Card from '../../../containers/card';
import DepopToolbar from './depop.toolbar';
import DepopPreview from './depop.preview';
import DepopRuns from './depop.runs';
import BarCharts from './depop.barcharts';
import DepopMetrics from './depop.metrics';
import DepopComment from './depop.comment';
import PlaceholderImg from '../../../assets/empty-placeholder.png';

const BackButton = () => (
    <Button
        type="link back"
        onClick={() => history.push('/depops')}
    >
        <Icon type="left-circle" theme="filled" />
        Back
    </Button>
);

const InitialDepop = {
    metrics: {},
    runMetrics: {},
    comment: null,
    client: {}
};

const Depop = ({ params: { depopId } }) => {
    const [loading, setLoading] = useState(true);
    const [depop, setDepop] = useState(InitialDepop);

    const { client } = depop;
    const { logo } = client;
    const img = logo || PlaceholderImg;

    useEffect(() => {
        actions.get(depopId)
            .then(data => setDepop(data))
            .finally(() => setLoading(false));
    }, [depopId]);

    const handleDepopChange = data => setDepop(data);

    return (
        <div className="depop">
            <div className="tool-bar">
                <div className="container">
                    <BackButton />
                    <DepopToolbar
                        depopId={depopId}
                        status={depop.status}
                        onChange={handleDepopChange}
                    />
                </div>
            </div>
            <div className="container">
                <Row className="depop-title-container">
                    <Col xs={14}>
                        <Col xs={6} md={3} className="depop-title-logo-container">
                            <img src={img} alt="Logo" />
                        </Col>
                        <Col xs={18} md={21}>
                            <Col xs={24}>
                                <h2>{depop.name}</h2>
                            </Col>
                            <Col xs={24}>
                                <span>
                                    Customer: {client.name}
                                </span>
                            </Col>
                        </Col>
                    </Col>
                    <Col xs={8} push={2}>
                        <h2>Depop ID: {depop.id}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <Card title="Depop Info">
                            <DepopPreview
                                depop={depop}
                                loading={loading}
                                view="edit"
                            />
                        </Card>
                        {
                            depop.comment && (
                                <Card title="Depop Comment">
                                    <DepopComment comment={depop.comment} id={depop.id} />
                                </Card>
                            )
                        }
                    </Col>
                    <Col xs={8}>
                        <Card title="Depop Data">
                            <div className="depop-data-list">
                                <h2>Depop Metrics</h2>
                                <Divider />
                                <DepopMetrics metrics={depop.runMetrics || { loading: false }} />
                                <h2>Depop Performance</h2>
                                <Divider />
                                <BarCharts
                                    data={depop.runMetrics || { loading: false }}
                                    legend
                                    labelOnTop
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col xs={8} className="depop-runs-list">
                        <Card title="Assigned Runs">
                            <DepopRuns
                                depop={depop}
                                loading={loading}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

Depop.propTypes = {
    params: PropTypes.object.isRequired
};

export default Depop;
