export const AuthConstants = {
    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',
    LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTH_LOGOUT_FAILURE',
    PROFILE_CHANGED: 'AUTH_USER_PROFILE_CHANGED',
    PROFILE_ACU_CHANGED: 'AUTH_USER_ACU_CHANGED',
    LOCATIONS_ACU_LOADED: 'AUTH_LOCATIONS_ACU_LOADED',
};

export const ClientConstants = {
    CLIENT_UPDATED: 'CLIENT_UPDATED',
    CLIENT_LOADED: 'CLIENT_LOADED',
    CLIENT_CREATED: 'CLIENT_CREATED',
    CLIENT_CLEAR: 'CLIENT_CLEAR'
};
