import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Form } from 'antd';
import { localDate } from '../../../helpers';
import { Item } from '../../../components/form';

const ClientDetails = ({ client }) => (
    <Spin spinning={client === {}}>
        <Form 
            className="readonly"
            layout="horizontal"
            
        >
            <h1>{client.name}</h1>
            <Item label="Barn #">{client.barn}</Item>
            
            <Item label="Run Upload Date">{localDate(client.createdAt)}</Item>
            <Item label="Run Start Date">{localDate(client.startDate)}</Item>
            <Item label="Run End Date">{localDate(client.finishDate)}</Item>
            <Item label="Production System">{client.productionSystem}</Item>
            <Item label="Hen Type / Breed">{client.henType}</Item>
            <Item label="Est. # of Hens">{client.estimatedNumberOfHens}</Item>
            <Item label="Depop Type">{client.depopType}</Item>
            <Item label="Weeks of Age">{client.weeksOfAge}</Item>
            <Item label="Hens / Cart">{client.hensPerCart || 'N/A'}</Item>
            
            <Item label="Processed Hens">{client.totalDepop || 'N/A'}</Item>
            <Item label={(<div>CO<sub>2</sub> per hen</div>)}>{client.CO2PerHen || 'N/A'} g</Item>
            <Item label="Percent in Range">{client.percentInRange || 'N/A'}</Item>
            <Item label="Depop Duration">N/A</Item>
            
        </Form>
    </Spin>
);

ClientDetails.propTypes = {
    client: PropTypes.object.isRequired
};

export default ClientDetails;
