import './sider.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Secured } from '../../../components';

const MenuItem = Menu.Item;

const Sider = ({ current }) => (
    <>
        <Secured grant="ADMIN">
            <Menu
                className="sider"
                defaultSelectedKeys={[current]}
                mode="inline"
            >   
                <MenuItem key="/dashboard">
                    <Link to="/dashboard">
                        <Icon type="area-chart" />
                        Dashboard
                    </Link>
                </MenuItem>
                <MenuItem key="/depops">
                    <Link to="/depops">
                        <Icon type="switcher" />
                        Depops
                    </Link>
                </MenuItem>
                <MenuItem key="/unassigned-runs">
                    <Link to="/unassigned-runs">
                        <Icon type="rocket" />
                        Unassigned Runs
                    </Link>
                </MenuItem>
                <MenuItem key="/runs">
                    <Link to="/runs">
                        <Icon type="rocket" />
                        View of all Runs
                    </Link>
                </MenuItem>
                <MenuItem key="/machines">
                    <Link to="/machines">
                        <Icon type="box-plot" />
                        Machine Management
                    </Link>
                </MenuItem>
                <MenuItem key="/clients">
                    <Link to="/clients">
                        <Icon type="team" />
                        Clients
                    </Link>
                </MenuItem>
            </Menu>
        </Secured>
        <Secured grant="CLIENT">
            <Menu
                className="sider"
                defaultSelectedKeys={[current]}
                mode="inline"
            >   
                <MenuItem key="/dashboard">
                    <Link to="/dashboard">
                        <Icon type="area-chart" />
                        Dashboard
                    </Link>
                </MenuItem>
                <MenuItem key="/depops">
                    <Link to="/depops">
                        <Icon type="switcher" />
                        Depops
                    </Link>
                </MenuItem>
                <MenuItem key="/unassigned-runs">
                    <Link to="/unassigned-runs">
                        <Icon type="rocket" />
                        Unassigned Runs
                    </Link>
                </MenuItem>
                <MenuItem key="/runs">
                    <Link to="/runs">
                        <Icon type="rocket" />
                        View of all Runs
                    </Link>
                </MenuItem>
                <MenuItem key="/clients/mc">
                    <Link to="/clients/mc">
                        <Icon type="user" />
                        Client Profile
                    </Link>
                </MenuItem>   
            </Menu>
        </Secured>
    </>
);

Sider.propTypes = {
    current: PropTypes.string.isRequired
};

const mapStateToProps = ({ router = {}, authentication: { user: { clientId } } }) => {
    const path = router.location.pathname;
    const res = path.split('/').slice(1, 2).join('/');
    return {
        current: `/${res}`,
        clientId
    };
};

export default connect(mapStateToProps)(Sider);
