// eslint-disable-next-line import/no-cycle
import api from './_api';

const Path = '/clients';

const transformIn = (data) => {
    const emails = data.emails ? data.emails.split(',') : [];
    return { ...data, emails };
};

const transformOut = (data) => {
    const { emails } = data;
    if (!emails) return data;
    return { ...data, emails: emails.join(',') };
};

const list = () => api
    .get(Path)
    .then(({ data }) => data.map(item => ({
        ...item,
        farmsNum: item.farms.length,
        mashinesNum: item.machines.length
    })))
    .catch(error => Promise.reject(error));

const get = id => api
    .get(`${Path}/${id}`).then(data => transformIn(data));

const update = (id, values) => api
    .put(`${Path}/${id}`, transformOut(values));

const create = data => api
    .post(Path, data);

const addUserToClient = data => api
    .post('/users', data);

const getUsersForClient = id => api
    .get(`${Path}/${id}/users`);

const deleteUserFromClient = (userId, clientId) => api
    .delete(`${Path}/${clientId}/user/${userId}`);

export const ClientService = {
    list,
    get,
    update,
    create,
    getUsersForClient,
    addUserToClient,
    deleteUserFromClient,
};
