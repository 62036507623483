import './user.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Drawer, Dropdown, Icon, Menu } from 'antd';
import { AuthActions as actions } from '../../../actions';
import FormLogin from '../../forms/FormLogin';

class UserMenu extends Component {
    handleLogout = this.props.logout

    menuItems = (
        <Menu>
            <Menu.Item>
                <Link to="/profile">My Profile</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" onClick={this.handleLogout}>
                Logout
            </Menu.Item>
        </Menu>
    );

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }

    toggleLogin = (visible) => {
        this.setState({ visible });
    }

    render() {
        const { loggedIn, user } = this.props;
        const { visible } = this.state;
        // eslint-disable-next-line prefer-const
        let { firstName = 'Unknown', lastName = '' } = user;

        if (!loggedIn) {
            return (
                <>
                    <Button type="link" className="user-menu" onClick={() => this.toggleLogin(true)}>
                        Login
                    </Button>
                    <Drawer
                        width={400}
                        placement="left"
                        closable
                        onClose={() => this.toggleLogin(false)}
                        visible={visible}
                    >
                        <h1>Login</h1>
                        <FormLogin />
                    </Drawer>
                </>
            );
        }
        if (!firstName) {
            firstName = 'Unknown';
        }

        return (
            <Dropdown className="user-menu" overlay={this.menuItems}>
                <Link to="/profile">
                    <Avatar shape="square" size="small" icon="user" />
                    <span>{firstName} {lastName}</span>
                    <Icon type="down" />
                </Link>
            </Dropdown>
        );
    }
}

UserMenu.propTypes = {
    user: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = ({ authentication: { user, loggedIn } }) => ({ user, loggedIn });

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(actions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
