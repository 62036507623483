import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const Paths = {
    home: '/',
    accessDenied: '/403'
};

const hasAccess = (role = 'PUBLIC', access) => access.includes(role);

const redirect = (path, location) => (
    <Redirect to={{ 
        pathname: path, 
        state: { from: location } 
    }} 
    />
);

const ProtectedRoute = (props) => {
    const { 
        user, loggedIn, loggingIn,
        component, access, path, 
        params, computedMatch, location 
    } = props;

    const getRoute = () => (
        <Route 
            key={path} 
            render={() => (
                React.createElement(component, {
                    params: { ...computedMatch.params, ...params }
                })
            )}
        />
    );
    if (loggedIn) {
        if (hasAccess(user.role, access)) return getRoute();
        return redirect(Paths.accessDenied, location);
    }

    if (hasAccess(user.role, access)) return getRoute();
    
    if (loggingIn) return null;
    
    return redirect(Paths.home, location);
};

export default ProtectedRoute;
