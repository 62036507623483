import React, { Component } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DepopActions as actions, FarmActions } from '../../../actions';
import { DepopModel as model } from '../../../models';

import Form, { FormItem } from '../../../components/form';

class DepopEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depop: {},
            farms: [],
            loading: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { depop } = props;
        if (!Object.equals(depop, state.depop)) {
            return { depop };
        }
        return null;
    }

    componentDidMount() {
        const { depop } = this.state;
        const { id } = depop.client;
        FarmActions.list(id)
            .then(farms => farms.map(item => ({
                value: item.id, text: item.name
            })))
            .then((farms) => {
                this.setState({ farms });
            });
    }

    handleSubmit = async (values) => {
        this.setState({ loading: true });
        const { depop, onChange } = this.props;
        const { id } = depop;
        actions.update(id, { ...values })
            .then(this.setState({ depop: { ...depop, values }, loading: false }))
            .then(onChange)
            .then(this.props.onCancel);
    };

    render() {
        const { farms, depop, loading } = this.state;
        const { onCancel } = this.props;
        const { handleSubmit } = this;
        // eslint-disable-next-line max-len
        const initialValues = { ...depop, startDate: moment(depop.startDate), farms, farm: depop.farm.id };
        return (
            <Spin
                spinning={loading}
            >
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    ref={inst => this.form = inst}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <FormItem {...model.farmName} options={farms} />
                        </Col>
                        <Col span={12}>
                            <FormItem {...model.barnName} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <FormItem className="form-item-date" {...model.depopStartDate} />
                        </Col>
                        <Col span={8}>
                            <FormItem {...model.depopType} />
                        </Col>
                        <Col span={8}>
                            <FormItem {...model.productionSystems} />
                        </Col>
                    </Row>
                    <Row className="full-width-number-cell" gutter={16}>
                        <Col span={8}>
                            <FormItem {...model.henType} />
                        </Col>
                        <Col span={8}>
                            <FormItem {...model.hensInBarn} />
                        </Col>
                        <Col span={8}>
                            <FormItem {...model.weeksOfAge} />
                        </Col>
                    </Row>
                    <Button
                        type="primary right"
                        htmlType="submit"

                    >Update
                    </Button>
                    <Button
                        type="default right"
                        onClick={onCancel}
                    >Cancel
                    </Button>
                </Form>
            </Spin>
        );
    }
}

DepopEdit.propTypes = {
    depop: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

export default DepopEdit;
