import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Input, Icon } from 'antd';
import { AuthActions as actions } from '../../actions';
import { UserModel as model } from '../../models';
import Form, { FormItem } from '../../components/form';
import { getQueryStringValue, history } from '../../helpers';

class Validate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
        };
    }

    componentDidMount() {
        const token = getQueryStringValue('token');
        if (!token) {
            history.push('/');
        }
    }

    handleSubmit = (values) => {
        const { validate } = this.props;
        const token = getQueryStringValue('token');
        const data = { 
            token,
            ...values
        };
        return validate(data);
    }

    handleConfirmBlur = (e) => {
        const { value } = e.target;
        this.setState(prevState => ({ confirmDirty: prevState.confirmDirty || !!value }));
    }

    validatePasswordConfirm = (rule, value, callback) => {
        if (value && value !== this.form.getFieldValue('password')) {
            callback('Password doesn\'t match');
        } else {
            callback();
        }
    }

    validatePassword = (rule, value, callback) => {
        if (value && this.state.confirmDirty) {
            this.form.validateFields(['confirm']);
        }
        callback();
    }

    render() {
        const thisModel = {
            password: model.password(this.validatePassword),
            confirm: model.confirm(this.validatePasswordConfirm)
        };

        return (
            <div className="container">
                <Row className="validate-password">
                    <Col sm={{ span: 20, push: 2 }} md={{ span: 8, push: 8 }}>
                        <div className="validate-locker">
                            <Icon type="lock" />
                        </div>
                        <h1>Set a password</h1>
                        <Form
                            initialValues={{}}
                            hideRequiredMark
                            wrappedComponentRef={inst => this.form = inst}
                            onSubmit={this.handleSubmit}
                        >
                            <FormItem {...thisModel.password}>
                                <Input
                                    type="password"
                                    placeholder="Password"
                                    autoComplete="none"
                                />
                            </FormItem>
                            <FormItem {...thisModel.confirm}>
                                <Input
                                    type="password"
                                    placeholder="Repeat Password"
                                    autoComplete="none"
                                    onBlur={this.handleConfirmBlur}
                                />
                            </FormItem>
                            <Button
                                type="primary left"
                                htmlType="submit"
                                block
                                style={{ width: '50%' }}
                            >Set
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    validate: data => dispatch(actions.validate(data))
});

export default connect(null, mapDispatchToProps)(Validate);
