import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Row, Col, Divider, Modal } from 'antd';
import { localDate } from '../../../helpers';
import { RunActions as actions } from '../../../actions';
import { Card } from '../../../containers';
import RunCharts from '../runs/run.charts';
import RunStatistics from '../runs/run.statistics';
import RunCharacteristics from '../runs/run.characteristics';
import BarCharts from '../runs/run.barcharts';

const RunDetails = ({ onClose }) => {
    const [run, setRun] = useState({});
    const [loading, setLoading] = useState(true);
    const { runId } = useParams();
    const { farm, startDate, notes } = run;

    useEffect(() => {
        const fetch = async () => {
            const result = await actions.get(runId);
            setRun(result);
            setLoading(false);
        };
        fetch();
    }, [runId]);
        
    if (loading) {
        return null;
    }
    
    return (
        <Modal
            title="Run Details"
            onCancel={() => onClose(runId)}
            confirmLoading={loading}
            width={1200}
            footer={null}
            visible
            style={{ top: 10 }}
        >   
            <Row>
                <Col span={24} className="submenu">
                    <h1 className="right">ID: {runId}</h1>
                    <h1>{farm && farm.client && farm.client.name}</h1>
                    <h4>Run Date: {localDate(startDate)}</h4>
                </Col>
                <Col span={8}>
                    <Card title="Run Data">
                        <RunCharacteristics {...run} />
                        <h3>Run Performance</h3>
                        <Divider />
                        <BarCharts run={run} legend />
                        <RunStatistics {...run} />
                    </Card>
                    <Card title="Comment">
                        {notes}
                    </Card>
                </Col>
                <Col span={16}>
                    <Card title="Run Statistics" expandable>
                        <RunCharts id={runId} />
                    </Card>
                    <Card title="List of Events" expandable />
                </Col>
            </Row>
        </Modal>
    );
};

RunDetails.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default RunDetails;
