import './header.scss';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Layout, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Navigation from './navigation';
import UserMenu from './user';

const { Header } = Layout;

const AppHeader = ({ current, loggedIn }) => (
    <>
        <Header>
            <div className="container">
                <Row type="flex" justify="center">
                    <Col span={2}>
                        <Link to="/">
                            <div className="logo" />
                        </Link>
                    </Col>
                    <Col span={18}>
                        {!loggedIn && <Navigation current={current} />}
                    </Col>
                    <Col span={4}>
                        <UserMenu />
                    </Col>
                </Row>
            </div>
        </Header>
        <div />
    </>
);

AppHeader.propTypes = {
    current: PropTypes.string,
};

AppHeader.defaultProps = {
    current: '',
};

const mapStateToProps = ({ router = { location: {} }, authentication: { loggedIn } }) => (
    {
        current: router.location.pathname,
        loggedIn
    }
);

export default connect(mapStateToProps)(AppHeader);
