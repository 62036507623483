import { AuthConstants as constants } from '../constants';
import { LocalService } from '../services/local.service';
// eslint-disable-next-line import/no-cycle
import { Roles } from '../models';

const loggingIn = !!LocalService.token();

const initialState = Object.freeze({ 
    loggedIn: false, 
    loggingIn,
    user: { role: Roles.PUBLIC },
});

const authentication = (state = initialState, action) => {
    switch (action.type) {
        case constants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                loggedIn: false,
                user: { 
                    ...state.user, 
                    ...action.user 
                }
            };
        case constants.LOGIN_SUCCESS: {
            LocalService.token(action.user.token);
            return {
                ...initialState,
                loggingIn: false,
                loggedIn: true,
                user: { ...state.user, ...action.user },
            };
        }
        case constants.LOGIN_FAILURE:
            return {
                ...initialState,
                loggingIn: false,
                loggedIn: false,
                loginError: action.error,
            };
        case constants.PROFILE_CHANGED: {
            return {
                ...state,
                loggingIn: false,
                loggedIn: true,
                user: { 
                    ...state.user, 
                    ...action.user,
                },
            };
        }
        case constants.LOGOUT_SUCCESS: {
            LocalService.token(false);
            return {
                ...initialState,
                loggingIn: false,
                loggedIn: false
            };
        }
            
        default:
            return state;
    }
};

export { authentication };
