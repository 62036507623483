import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import RunChart from './run.chart';
import { RunActions as actions } from '../../../actions';
import { sensorConfig } from '../../../models';

class RunCharts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            sensors: {},
        };
    }

    async componentDidMount() {
        const { id } = this.props;
        const sensors = await actions.getData(id);
        this.setState({ sensors, loading: false });
    }

    render() {
        const { loading, sensors } = this.state;
        
        return sensorConfig.map((sensor) => {
            const sensorData = sensors[sensor.id] || { data: [], average: 0 };
            return (
                <Row key={sensor.id}>
                    <Col span={3} className="CO2Titles">
                        <h2>STAGE {sensor.id + 1}</h2>
                        <h1 className={sensorData.inRange}>{sensorData.average}%</h1>
                    </Col>
                    <Col span={21}>
                        <RunChart 
                            id={sensor.id}
                            loading={loading}
                            data={sensorData.data} 
                        />
                    </Col>
                </Row>
            );
        });
    }
}

RunCharts.propTypes = {
    id: PropTypes.string.isRequired,
};

export default RunCharts;
