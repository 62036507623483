import React from 'react';
import { Layout, Row, Col } from 'antd';
import './footer.scss';

const { Footer } = Layout;

export default () => (
    <>
        <Footer>
            <div className="container">
                <Row>
                    <Col xs={5}>
                        <h4>Humane Euthanasia Network, Inc. (HEN)</h4>
                    </Col>
                    <Col xs={5}>
                        <h4>The Science</h4>
                    </Col>
                    <Col xs={5}>
                        <h4>Products & Certification</h4>
                    </Col>
                    <Col xs={5}>
                        <h4>Network Members</h4>
                    </Col>
                    <Col xs={4} style={{ textAlign: 'right' }}>
                        <div>1-866-HEN-2222</div>
                        <div>(1-866-826-2222)</div>
                    </Col>
                </Row>
            </div>
        </Footer>
    </>
);
