/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Checkbox, DatePicker, Input, InputNumber, Select, Switch } from 'antd';
// eslint-disable-next-line import/no-cycle
import { CheckboxGroup, FileUpload, RadioGroup } from './fields';
import { DateFormat } from '../../config';

const { TextArea } = Input;

export const FieldTypes = {
    SELECTGROUP: 'SELECTGROUP',
    SELECT: 'SELECTLIST',
    TEXTAREA: 'TEXTAREA',
    INPUT: 'INPUT',
    DATE: 'DATE',
    TEXT: 'TITLE',
    NUMBER: 'NUMBER',
    CHECKBOX: 'CHECKBOX',
    CHECKBOXGROUP: 'CHECKBOXLIST',
    RADIOGROUP: 'RADIOBUTTONLIST',
    SWITCH: 'SWITCH',
    SLIDER: 'SLIDER',
    RATEIMAGES: 'RATEIMAGES',
    SORT: 'SORT',
    UPLOAD: 'UPLOAD'
};

const Field = (props) => {
    const {
        options,
        type,
        ...restProps
    } = props;

    switch (type) {
        case FieldTypes.CHECKBOXGROUP: {
            return CheckboxGroup(options, props);
        }
        case FieldTypes.CHECKBOX: {
            return <Checkbox {...restProps} />;
        }
        case FieldTypes.SELECT: {
            return (
                <Select {...restProps}>
                    {options.map((item, idx) => (
                        <Select.Option
                            key={idx}
                            value={item.value}
                        >
                            {item.text}
                        </Select.Option>
                    ))}
                </Select>
            );
        }
        case FieldTypes.SELECTGROUP: {
            return <Select {...restProps} mode="tags" />;
        }
        case FieldTypes.UPLOAD:
            return <FileUpload {...restProps} />;
        case FieldTypes.RADIOGROUP:
            return <RadioGroup {...restProps} options={options} />;
        case FieldTypes.TEXTAREA:
            return <TextArea rows={4} {...restProps} />;
        case FieldTypes.TEXT:
            return <div className={restProps.class}>{restProps.children}</div>;
        case FieldTypes.DATE:
            return <DatePicker format={DateFormat} {...restProps} />;
        case FieldTypes.SWITCH:
            return <Switch {...restProps} />;
        case FieldTypes.NUMBER:
            return <InputNumber {...restProps} />;
        default:
            return <Input {...restProps} />;
    }
};

const maxRule = (num, value, callback) => {
    if (Object.isObject(value)) {
        value = Object.keys(value);
    }

    if (value && value.length && value.length > num) {
        return callback(`Please, select up to ${num} elements.`);
    }
    callback();
};

const minRule = (num, value, callback) => {
    if (Object.isObject(value)) {
        value = Object.keys(value);
    }

    if (value && value.length && value.length < num) {
        return callback('All fields are required');
    }
    callback();
};

export const FieldRules = {
    REQUIRED: {
        required: true,
        message: 'This field is required.'
    },
    PASSWORD: {
        pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        message: 'Password must be at least 8 characters including one uppercase letter, one lowercase letter and 1 number.'
    },
    EMAIL: {
        type: 'email',
        message: 'The input is not valid E-mail.'
    },
    EMAILS: {
        pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g,
        message: 'The input is not valid E-mail.'
    },
    NUMBER: {
        pattern: /^[0-9]*\.{0,1}[0-9]*$/,
        message: 'The input is not valid Number.'
    },
    ZIP: {
        pattern: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
        message: 'The input is not valid ZIP code.'
    },
    PHONE: {
        pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        message: 'The input is not valid Phone Number.'
    },
    URL: {
        pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        message: 'The input is not valid URL'
    },
    MAXITEMS3:
        (rule, value, callback) => maxRule(3, value, callback),
    MINITEMS3:
        (rule, value, callback) => minRule(3, value, callback),
    MINITEMS5:
        (rule, value, callback) => minRule(5, value, callback),
    MINITEMS6:
        (rule, value, callback) => minRule(6, value, callback),
    DATEINPAST: (rule, value, callback) => {
        if (value && value >= moment()) return callback('The Date should be in the past.');
        return callback();
    }
};

Field.propTypes = {
    type: PropTypes.string.isRequired,
    options: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

Field.defaultProps = {
    children: {},
    options: []
};

export default Field;
