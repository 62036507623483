import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import Form, { FormItem } from '../../components/form';
import { UserModel as model } from '../../models';

const FormProfile = (props) => {
    const { user, onSubmit } = props;

    return (
        <Form
            initialValues={user}
            onSubmit={onSubmit}
        >
            <Row gutter={24}>
                <Col lg={12}>
                    <FormItem {...model.firstName} />
                </Col>
                <Col lg={12}>
                    <FormItem {...model.lastName} />
                </Col>
                <Col lg={24}>
                    <FormItem {...model.phone} />
                </Col>
                <Col lg={24}>
                    <FormItem {...model.address1} />
                </Col>
                <Col lg={24}>
                    <FormItem {...model.address2} />
                </Col>
                <Col lg={12}>
                    <FormItem {...model.city} />
                </Col>
                <Col lg={12}>
                    <FormItem {...model.zip} />
                </Col>
                <Col lg={24}>
                    <FormItem {...model.state} />
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Button
                        type="primary right"
                        htmlType="submit"
                    >
                        Update
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

FormProfile.propTypes = {
    user: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default FormProfile;
