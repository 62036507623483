import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Input } from 'antd';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthActions as actions } from '../../actions';
import Form, { FormItem } from '../../components/form';
import { UserModel as model } from '../../models';

class FormLogin extends React.Component {
    handleSubmit = (values) => {
        const { login } = this.props;
        return login(values);
    }

    render() {
        const { loggingIn, loggedIn } = this.props;
        if (loggedIn) {
            return <Redirect to="/" />;
        }

        const thisModel = {
            email: model.email,
            password: model.password(),
        };

        return (
            <Form
                initialValues={{}}
                hideRequiredMark
                onSubmit={this.handleSubmit}
                spin={false}
            >
                <FormItem {...thisModel.email}>
                    <Input
                        type="email"
                        placeholder="Email Address"
                    />
                </FormItem>
                <FormItem {...thisModel.password}>
                    <Input
                        type="password"
                        placeholder="Password"
                        autoComplete="none"
                    />
                </FormItem>
                <Button
                    loading={loggingIn}
                    block
                    type="primary"
                    htmlType="submit"
                >
                    Login
                </Button>
            </Form>
        );
    }
}

FormLogin.propTypes = {
    login: PropTypes.func.isRequired,
    loggingIn: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    const { loggingIn, loginError, loggedIn } = state.authentication;
    return {
        loggingIn,
        loginError,
        loggedIn
    };
}

const mapDispatchToProps = dispatch => ({
    clearError: () => dispatch(actions.clear()),
    login: values => dispatch(actions.login(values)),
});

const ConnectedFormLogin = connect(mapStateToProps, mapDispatchToProps)(FormLogin);
export default ConnectedFormLogin;
