const getterSetter = name => (...args) => {
    if (!args.length) {
        return localStorage.getItem(name);
    }
    const value = args[0];
    if (value === false) {
        return localStorage.removeItem(name);
    }
    return localStorage.setItem(name, value);
};

export const token = getterSetter('token');

export const LocalService = {
    email: getterSetter('email'),
    token,
};
