import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import AdminSider from './sider';

const { Content, Sider } = Layout;

const RouteWithSider = ({ children }) => (
    <div className="container">
        <Layout>
            <Sider theme="light">
                <AdminSider />
            </Sider>
            <Content>
                { children }
            </Content>
        </Layout>
    </div>
);

RouteWithSider.propTypes = {
    children: PropTypes.any.isRequired
};

export default RouteWithSider;
