import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RouteWithSider from './sider';
import FormProfile from '../../containers/forms/FormProfile';
import { AuthActions as actions } from '../../actions';

const ProfileRoute = (props) => {
    const { user, update } = props;

    const handleSubmit = values => update(values);

    return (
        <RouteWithSider>
            <FormProfile 
                user={user}
                onSubmit={handleSubmit} 
            />
        </RouteWithSider>
    );    
};

ProfileRoute.propTypes = {
    user: PropTypes.object.isRequired,
    update: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    update: data => dispatch(actions.updateLoggedUser(data))
});

const mapStateToProps = ({ authentication }) => ({ user: authentication.user });

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRoute);
