import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'antd';
import { ClientActions, DepopActions as actions, FarmActions } from '../../../actions';
import { DepopModel as model, Roles } from '../../../models';
import { Secured } from '../../../components';
import Form, { FormItem } from '../../../components/form';
import { history } from '../../../helpers';

const NewDepop = ({ user, onCancel }) => {
    const [clientId, setClientId] = useState();
    const [clients, setClients] = useState([]);
    const [farms, setFarms] = useState([]);
    let form = useRef();

    const getClients = () => ClientActions.list()
        .then(data => data.map(item => ({
            value: item.id, text: item.name
        })))
        .then(data => setClients(data));

    const handleSubmit = values => actions
        .create(values)
        .then(({ id }) => history.push(`/depops/${id}`));

    useEffect(() => {
        const getFarms = (...args) => {
            FarmActions.list(...args)
                .then(data => data.map(item => ({
                    value: item.id, text: item.name
                })))
                .then((data) => {
                    form.resetFields(['farm']);
                    setFarms(data);
                });
        };
        if (clientId) {
            getFarms(clientId);
        }
    }, [clientId]);

    useEffect(() => {
        if (user.role === Roles.ADMIN) {
            getClients();
        } else {
            setClientId('mc');
        }
    }, [user]);

    return (
        <Form
            loading
            initialValues={{}}
            onSubmit={handleSubmit}
            ref={inst => form = inst}
        >
            <Row gutter={16}>
                <Secured grant="ADMIN">
                    <Col span={8}>
                        <FormItem
                            {...model.client}
                            onChange={setClientId}
                            options={clients}
                        />
                    </Col>
                    <Col span={8}>
                        <FormItem {...model.farmName} options={farms} />
                    </Col>
                </Secured>
                <Secured grant="CLIENT">
                    <Col span={8}>
                        <FormItem {...model.farmNameForClient} options={farms} />
                    </Col>
                </Secured>
                <Col span={8}>
                    <FormItem {...model.barnName} />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <FormItem className="form-item-date" {...model.depopStartDate} />
                </Col>
                <Col span={8}>
                    <FormItem {...model.depopType} />
                </Col>
                <Col span={8}>
                    <FormItem {...model.productionSystems} />
                </Col>
            </Row>
            <Row className="full-width-number-cell" gutter={16}>
                <Col span={8}>
                    <FormItem {...model.henType} />
                </Col>
                <Col span={8}>
                    <FormItem {...model.hensInBarn} />
                </Col>
                <Col span={8}>
                    <FormItem {...model.weeksOfAge} />
                </Col>
            </Row>
            <Button
                type="primary right"
                htmlType="submit"
            >Create
            </Button>
            <Button
                type="default right"
                onClick={onCancel}
            >Cancel
            </Button>
        </Form>
    );
};

NewDepop.propTypes = {
    onCancel: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = ({ authentication: { user } }) => ({ user });

export default connect(mapStateToProps)(NewDepop);
