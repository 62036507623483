/* eslint-disable prefer-promise-reject-errors */
// eslint-disable-next-line import/no-cycle
import api from './_api';
// eslint-disable-next-line import/no-cycle
import { sensorConfig } from '../models';

const Path = '/runs';

const averageFn = (data, prop) => {
    const sum = data.reduce((acc, item) => {
        const value = prop ? item[prop] : item;
        return acc + parseInt(value, 10);
    }, 0);
    return Math.ceil(sum / (data.length || 1));
};

const fakeData = (num, value, padding) => Array
    .from(new Array(num))
    .map((item, index) => (
        {
            time: index,
            co2: Math.floor(Math.random() * padding) + value,
        }
    ));

const getReducedData = (data, pn = 100) => {
    let reduceTo = pn;
    const dataLen = data.length;
    const perPeriod = Math.floor(dataLen / reduceTo);

    if (perPeriod === 1) {
        reduceTo = dataLen;
    }

    const reducedData = Array.from(new Array(reduceTo));

    return reducedData.map((item, index) => {
        const from = index * perPeriod;
        const isLast = index === reduceTo - 1;
        const to = isLast ? dataLen : (index + 1) * perPeriod;

        const records = data
            .slice(from, to)
            .map(i => parseInt(i.co2, 10));

        const min = Math.min(...records);
        const max = Math.max(...records);

        return {
            time: Math.ceil((index * perPeriod) / 2),
            average: averageFn(records),
            min,
            max,
        };
    });
};

const transformerIn = data => data.map((sensor) => {
    const { average, id } = sensor;
    const { bounds } = { ...sensorConfig[id] };

    let inRange = 'red';
    if (average >= bounds[1] && average <= bounds[2]) {
        inRange = 'green';
    } else if (average > bounds[0] && average < bounds[3]) {
        inRange = 'yellow';
    }
    return { ...sensor, inRange };
});

const getMockPromice = (total, reduceTo) => new Promise((resolve) => {
    const data = [
        { id: 0, average: 20, data: getReducedData(fakeData(total, 25, 10), reduceTo) },
        { id: 1, average: 50, data: getReducedData(fakeData(total, 60, 30), reduceTo) },
        { id: 2, average: 55, data: getReducedData(fakeData(total, 80, 20), reduceTo) },
        { id: 3, average: 70, data: getReducedData(fakeData(total, 70, 30), reduceTo) },
    ];
    resolve(transformerIn(data));
});

const USE_MOCK = false;

const list = (params) => api.get(`${Path}${params}`)
        .then(data => data.data)
        .catch(error => Promise.reject(error));

const listByClient = clientId => list(`/client/${clientId}`);

const listUnassigned = () => api.get(`${Path}/unassigned`)
    .then(({ data }) => data)
    .catch(error => Promise.reject(error));

const get = id => api
    .get(`${Path}/${id}`);

const getData = (id) => {
    if (USE_MOCK) {
        return getMockPromice(1000, 100);
    }
    return api.get(`${Path}/${id}/data`)
        .then(({ data }) => transformerIn(data));
};

const update = (id, values) => api
    .put(`${Path}/${id}`, values);

const recalculate = id => api
    .post(`/runs/${id}/calculations`);

const remove = runs => api
    .delete(`${Path}`, {
        data: { runs }
    });

export const RunService = {
    list,
    listUnassigned,
    listByClient,
    get,
    getData,
    update,
    recalculate,
    remove,
};
