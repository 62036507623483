import moment from 'moment';
import * as config from '../config';

const dateFormat = config.DateFormat;
const dateTimeFormat = config.DateTimeFormat;

export const ColumnTypes = {
    string: 'string',
    number: 'number',
    date: 'date',
    time: 'time',
    bool: 'bool'
};

export const sortFn = {
    [ColumnTypes.string]: name => (a, b) => {
        if (!a[name] && !b[name]) return 0;
        if (!a[name]) return -1;
        if (!b[name]) return 1;
        const c = a[name].toLowerCase();
        const d = b[name].toLowerCase();
        if (c < d) return -1;
        if (c > d) return 1;
        return 0;
    },
    [ColumnTypes.number]: name => (a, b) => a[name] - b[name],
    [ColumnTypes.time]: name => (a, b) => moment(a[name]).diff(moment(b[name]), 'minutes'),
    [ColumnTypes.date]: name => (a, b) => new Date(a[name]) - new Date(b[name]),
    [ColumnTypes.bool]: name => a => (a[name] ? -1 : 1)
};

export const renderFn = ({
    [ColumnTypes.date]: (value) => {
        if (!value) return '';
        return moment(value).format(dateFormat);
    },
    [ColumnTypes.time]: (value) => {
        if (!value) return '';
        if (value.indexOf('0000') === 0) return '';
        return moment(value).local().format(dateTimeFormat);
    },
    [ColumnTypes.bool]: value => (value ? 'Yes' : 'No'),
});
