import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Table } from 'antd';
import { RunActions as actions } from '../../../actions';
import { getQueryStringValue, history, localDate, sortFn } from '../../../helpers';
import RunPreview from './runs.preview';
import RouteWithSider from '../sider';
import { Card } from '../../../containers';

const columns = [
    {
        title: 'Farm, Barn #',
        dataIndex: 'farm',
        render: (farm, row) => (
            <div className="left">
                <h3>{farm ? farm.name : 'Unknown'}{row.barn && `, ${row.barn}`}</h3>
                <div className="table-date">{localDate(row.createdAt)}</div>
            </div>
        ),
        sorter: sortFn.date('createdAt'),
    },
    {
        title: 'Total Depop',
        dataIndex: 'processedHens',
        render: value => (value || 'N/A'),
        sorter: sortFn.number('processedHens'),
    },
    {
        title: <div>CO<sub>2</sub> per hen (g)</div>,
        dataIndex: 'co2WeightPerHenInGrams',
        render: value => (value || 'N/A'),
        sorter: sortFn.number('co2WeightPerHenInGrams'),
    },
    {
        title: '% in range',
        dataIndex: 'inAcceptableRange',
        render: (value, row) => (value || 0) + (row.inOptimalRange || 0),
        sorter: sortFn.number('inAcceptableRange'),
    }
];

class RunsRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
        };
        this.clientId = null;
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        this.clientId = getQueryStringValue('client');
        actions.list(this.clientId)
            .then((data) => {
                this.setState({ data, loading: false });
            });
    }

    gotoDetails = id => history.push(`/runs/${id}${this.clientId ? `?client=${this.clientId}` : ''}`);

    onRow = run => ({
        onClick: () => {
            history.push(`/runs/preview/${run.id}${this.clientId ? `?client=${this.clientId}` : ''}`);
        },
        onDoubleClick: () => this.gotoDetails(run.id)
    });

    getRowClassName = (run) => {
        const { params: { id } } = this.props;
        if (run.id && run.id.toString() === id) {
            return 'ant-table-row-selected';
        }
        return null;
    }

    render() {
        const { params: { id } } = this.props;
        const { data, loading } = this.state;

        let run = {};
        if (id) {
            run = Array.filterFirst(data, item => item.id.toString() === id) || {};
        }
        return (
            <RouteWithSider>
                <Row>
                    <Col xs={16}>
                        <Card title="All Runs">
                            <Table
                                loading={loading}
                                rowClassName={this.getRowClassName}
                                rowKey="id"
                                columns={columns}
                                dataSource={data}
                                onRow={this.onRow}
                            />
                        </Card>
                    </Col>
                    {id
                    && (
                        <Col xs={8}>
                            <Card title="Run Details">
                                <RunPreview
                                    run={run}
                                />
                                <Button
                                    type="primary right"
                                    onClick={() => this.gotoDetails(run.id)}
                                >
                                    View Run
                                </Button>
                            </Card>
                        </Col>
                    )}
                </Row>
            </RouteWithSider>
        );
    }
}

RunsRoute.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        clientId: PropTypes.string
    }).isRequired,
};

export default RunsRoute;
