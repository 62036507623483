import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
// import { logger } from 'redux-logger';
import { history } from '../helpers';
// eslint-disable-next-line import/no-cycle
import { authentication } from './auth.reducer';
import { client } from './client.reducer';
// eslint-disable-next-line import/no-cycle
import { AuthActions } from '../actions';

const router = connectRouter(history);

const RootReducer = combineReducers({
    authentication,
    client,
    router
});

const store = createStore(
    RootReducer,
    compose(
        applyMiddleware(
            routerMiddleware(history),
            thunkMiddleware,
            // logger
        ),
    )
);
export default store;

const { logoutSuccess } = AuthActions;

export const dispatchLogout = () => store.dispatch(logoutSuccess());
