import React from 'react';
import { Select as RawSelect } from 'antd';
import PropTypes from 'prop-types';

const { Option } = RawSelect;

const sortString = (a, b) => a.text > b.text;

class Select extends React.Component {
    constructor() {
        super();
        this.state = { value: undefined };
    }

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return { value: nextProps.value };
        }
        return null;
    }

    handleChange = (value) => {
        const { onChange } = this.props;
        this.setState({ value }, () => onChange(value));
    }

    render() {
        const { value } = this.state;
        const { options } = this.props;

        return (
            <RawSelect 
                showSearch
                value={value}
                onChange={this.handleChange} 
                dropdownMatchSelectWidth={false}
            >
                {options
                    .sort(sortString)
                    .map(item => (
                        <Option 
                            key={item.value} 
                            value={item.value}
                        >
                            {item.text}
                        </Option>
                    ))}
            </RawSelect>
        );
    }
} 

Select.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    // eslint-disable-next-line react/require-default-props
    value: PropTypes.string
};

Select.defaultProps = {
    options: [],
    onChange: () => {},
};

export { Select };
