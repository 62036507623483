// eslint-disable-next-line import/no-cycle
import { FieldRules, FieldTypes } from '../components/form/Field';
import { StateFilterFn, StateOptions } from '../config';

export const ClientModel = {
    clientName: {
        name: 'name',
        displayName: 'Client Name',
        placeholder: 'Add Client Name',
        type: FieldTypes.INPUT,
        validation: [
            FieldRules.REQUIRED
        ],
    },
    contactPerson: {
        name: 'contactPerson',
        displayName: 'Contact Person',
        placeholder: 'Add Contact Person',
        validation: [],
        type: FieldTypes.INPUT,
    },
    email: {
        name: 'email',
        displayName: 'Email Address',
        placeholder: 'Add Email',
        validation: [
            FieldRules.EMAIL
        ],
        type: FieldTypes.INPUT,
    },
    phone: {
        name: 'phoneNumber',
        displayName: 'Phone Number',
        placeholder: 'Add Phone Number',
        validation: [
            FieldRules.PHONE,
        ],
        type: FieldTypes.INPUT,
    },
    fax: {
        name: 'fax',
        displayName: 'Fax',
        placeholder: 'Add Fax',
        validation: [
            FieldRules.PHONE,
        ],
        type: FieldTypes.INPUT,
    },
    addressLine1: {
        name: 'addressLine1',
        displayName: 'Address Line 1',
        placeholder: 'Add Adress Line 1',
        type: FieldTypes.INPUT,
        validation: [],
    },
    addressLine2: {
        name: 'addressLine2',
        displayName: 'Address Line 2',
        placeholder: 'Add Adress Line 2',
        type: FieldTypes.INPUT,
    },
    city: {
        name: 'city',
        displayName: 'City',
        placeholder: 'Add City',
        type: FieldTypes.INPUT,
        validation: [],
    },
    state: {
        name: 'state',
        displayName: 'State',
        type: FieldTypes.SELECT,
        showSearch: true,
        allowClear: true,
        optionFilterProp: 'children',
        validation: [],
        filterOption: StateFilterFn,
        options: StateOptions
    },
    zip: {
        name: 'zip',
        displayName: 'Zip Code',
        placeholder: 'Add ZIP Code',
        validation: [
            FieldRules.ZIP
        ],
        type: FieldTypes.INPUT,
    },
    emails: {
        name: 'emails',
        displayName: 'Email Recipients',
        placeholder: 'Add client email adresses with enter',
        type: FieldTypes.SELECTGROUP,
        validation: [
            FieldRules.REQUIRED,
            FieldRules.EMAILS
        ],
    },
};
