import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Divider, Row } from 'antd';
import Card from '../../../containers/card/index';
import { ClientActions as actions } from '../../../actions';
import { Secured } from '../../../components';
import Form, { FormItem } from '../../../components/form';
import { ClientModel as model, Roles } from '../../../models';
import { history } from '../../../helpers';

class ClientGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };
    }

    componentDidMount() {
        const { params: { id } } = this.props;
        actions.get(id)
            .then(data => this.setState({ data }));
    }

    handleClientView = () => {
        const { params: { id } } = this.props;
        history.push(`/depops?client=${id}`);
    }

    handleSubmit = (values) => {
        const { params: { id } } = this.props;
        return actions.update(id, values)
            .then(data => this.setState({ data }));
    }

    render() {
        const { data } = this.state;
        const { user } = this.props;
        return (
            <Row>
                <Col xs={24} sm={24} md={16}>
                    <Card title="Contact Info">
                        <Form
                            wrappedComponentRef={inst => this.form = inst}
                            initialValues={data}
                            onSubmit={this.handleSubmit}
                            confirmOnLeave
                            disabled={user.role === Roles.CLIENT}
                        >
                            <h3>Head Office Contact Details</h3>
                            <Divider />
                            <Row gutter={24}>
                                <Col span={8}>
                                    <FormItem {...model.clientName} />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...model.email} />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...model.contactPerson} />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...model.phone} />
                                </Col>
                                <Col span={12}>
                                    <FormItem {...model.fax} />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...model.addressLine1} />
                                </Col>
                                <Col span={12}>
                                    <FormItem {...model.addressLine2} />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <FormItem {...model.city} />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...model.state} />
                                </Col>
                                <Col span={8}>
                                    <FormItem {...model.zip} />
                                </Col>
                            </Row>
                            <Secured grant="ADMIN">
                                <Button
                                    type="primary right"
                                    htmlType="submit"
                                >Save Changes
                                </Button>
                            </Secured>
                        </Form>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={8}>
                    <Card title="Operational Details" bordered={false}>
                        <div className="padding-client-card">
                            <h3>Metrics</h3>
                            <Divider />
                            <Row className="general-metrics">
                                <Row>
                                    <Col span={16}>
                                        <b><p>Total processed hens</p></b>
                                    </Col>
                                    <Col span={8}>
                                        <p>{data.processedHens ? data.processedHens : 0}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}>
                                        <b><p>Total number of runs</p></b>
                                    </Col>
                                    <Col span={8}>
                                        <p>{data.totalRuns ? data.totalRuns : 0}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}>
                                        <b><p>Number of assigned Machines</p></b>
                                    </Col>
                                    <Col span={8}>
                                        <p>{data.assignedMachines ? data.assignedMachines : 0}</p>
                                    </Col>
                                </Row>
                            </Row>
                            <Divider />
                            <b>All Depops({data.totalDepops})</b>
                            <Button type="primary" className="right" onClick={this.handleClientView}>View</Button>
                        </div>

                    </Card>
                </Col>
            </Row>
        );
    }
}

ClientGeneral.propTypes = {
    params: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = ({ authentication: { user } }) => ({ user });

export default connect(mapStateToProps)(ClientGeneral);
