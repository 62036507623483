import React, { useState, useEffect } from 'react';
import { Divider, Button, Modal, notification, Spin } from 'antd';
import PropTypes from 'prop-types';
import { DepopModel as model } from '../../../models';
import { DepopActions as actions } from '../../../actions';
import Form, { FormItem } from '../../../components/form';

const DepopComment = (props) => {
    const [visible, setVisible] = useState(false);
    const [comment, setComment] = useState(null);
    const [loading, setLoading] = useState(false);

    const { id, comment: initialComment } = props;

    useEffect(() => {
        setComment(initialComment);
    }, [initialComment]);

    const handleModalVisible = isVisible => setVisible(isVisible);

    const handleSubmit = async (values) => {
        setLoading(true);
        actions.update(id, { ...values })
            .then(() => setComment(values.comment))
            .then(() => setLoading(false))
            .then(() => handleModalVisible(false))
            .then(notification.success({
                message: 'Success',
                description: 'Comment has been updated!',
            }));
    };

    return (
        <div className="depop-data-list">
            <h2>Comment</h2>
            <Divider />
            {comment}
            <Divider />
            <Modal
                title="Edit Comment"
                visible={visible}
                onCancel={() => handleModalVisible(false)}
                footer={null}
            >
                <Spin spinning={loading}>
                    <Form
                        initialValues={{ comment: initialComment }}
                        onSubmit={handleSubmit}
                    >
                        <FormItem {...model.comment} />
                        <Button
                            type="primary right"
                            htmlType="submit"
                        >Edit
                        </Button>
                        <Button
                            type="default right"
                            onClick={() => handleModalVisible(false)}
                        >Cancel
                        </Button>
                    </Form>
                </Spin>
            </Modal>
            <Button type="primary right" onClick={() => handleModalVisible(true)}>Edit Comment</Button>
        </div>
    );
};

DepopComment.propTypes = {
    comment: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
};

export default DepopComment;
