/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as RawForm } from 'antd';
import Field, { FieldRules } from './Field';
import FormContext from './FormContext';

const RawFormItem = RawForm.Item;

const initValidation = (validation, currentValues) => {
    const res = [];

    if (!validation) {
        return res;
    }

    const fnToRule = item => ({
        validator: ((rule, value, callback) => {
            item(rule, value, callback, currentValues);
        })
    });

    const objectToRule = (item) => {
        const rule = {};
        Object.keys(item).forEach((name) => {
            let value = item[name];
            if (Object.isFunction(value)) {
                value = value(currentValues);
            }
            rule[name] = value;
        });
        return rule;
    };

    validation.forEach((item) => {
        let rule;
        if (Object.isFunction(item)) rule = fnToRule(item);
        else if (Object.isObject(item)) rule = objectToRule(item);
        else rule = FieldRules[item];
        res.push(rule);
    });
    return res;
};

export class FormItem extends Component {
    execDynamicProps = (propFn, name, currentValues) => {
        const value = this.context.getFieldValue(name);
        return propFn(currentValues, value);
    }

    render() {
        const {
            type,
            name,
            displayName,
            size,
            show,
            validation,
            required,
            tooltip,
            children,
            disabled,
            ...restProps
        } = this.props;

        // eslint-disable-next-line max-len
        const { initialValues, getFieldDecorator, getFieldsValue, disabled: formDisabled } = this.context;
        const currentValues = { ...initialValues, ...getFieldsValue() };

        if (show && !this.execDynamicProps(show, name, currentValues)) {
            return null;
        }

        if (disabled) restProps.disabled = this.execDynamicProps(disabled, name, currentValues);

        if (formDisabled) restProps.readOnly = true;

        const thisChildren = type ? Field({ type, ...restProps }) : children;

        if (!name) {
            return (
                <RawFormItem
                    label={displayName}
                    required={required}
                    extra={tooltip}
                >
                    {thisChildren}
                </RawFormItem>
            );
        }
        const rules = initValidation(validation, currentValues);
        const properties = {
            rules,
            valuePropName: 'value',
            displayName,
            ...this.props.props,
        };

        if (initialValues) {
            properties.initialValue = initialValues[name];
        }

        return (
            <RawFormItem
                label={displayName}
            >
                {
                    getFieldDecorator(name, properties)(thisChildren)
                }
            </RawFormItem>
        );
    }
}

FormItem.contextType = FormContext;

FormItem.propTypes = {
    name: PropTypes.string,
    displayName: PropTypes.any,
    validation: PropTypes.array,
    props: PropTypes.object,
    tooltip: PropTypes.element,
    type: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    required: PropTypes.bool,
    disabled: PropTypes.func,
    show: PropTypes.func,
    size: PropTypes.number,
};

FormItem.defaultProps = {
    name: '',
    displayName: '',
    validation: [],
    props: {},
    tooltip: null,
    type: '',
    required: false,
    disabled: () => {
    },
    children: [],
    show: null,
    size: 24,
};
