/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as CheckboxRaw } from 'antd';

class Checkbox extends React.Component {
    constructor() {
        super();
        this.state = { checked: false };
    }

    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return { checked: (nextProps.value) };
        }
        return null;
    }

    triggerChange = (value) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(value);
        }
    }

    render() {
        const { props } = this.props;
        const meta = this.props['data-__meta'];
        const { checked } = this.state;
        return (
            <CheckboxRaw
                checked={checked}
                onChange={this.triggerChange}
                {...props}
            >
                {meta.displayName}
            </CheckboxRaw>
        );
    }
}

Checkbox.propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
    props: PropTypes.object,
    'data-__meta': PropTypes.object
};

Checkbox.defaultProps = {
    value: false,
    onChange: () => {
    },
    props: {},
    'data-__meta': {}
};

export { Checkbox };
