export const RunStatus = Object.freeze({
    PENDING: 0,
    APPROVED: 1,
});

export const sensorConfig = [
    { 
        id: 0, 
        bounds: [25, 27, 33, 35],
        minScale: 10,
        maxScale: 45,
    },
    { 
        id: 1, 
        bounds: [40, 50, 100, 100], 
        minScale: 30,
        maxScale: 100,
    },
    { 
        id: 2, 
        bounds: [50, 60, 100, 100],
        minScale: 40,
        maxScale: 100,
    },
    { 
        id: 3, 
        bounds: [35, 45, 100, 100],
        minScale: 20,
        maxScale: 100, 
    }
];
