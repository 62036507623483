import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { Area, Axis, Chart, Guide, Line, Tooltip, View } from 'viser-react';
import { sensorConfig } from '../../../models';
import * as colors from '../../../assets/_variables.scss';
import { timeFormatter } from '../../../helpers';

const co2Formatter = value => `${value}%`;

const areaStyle = {
    fill: colors.brandTwo,
    lineWidth: 0,
    fillOpacity: 0.2,
};

const tooltipOpts = {
    crosshairs: { type: 'line' },
    showTitle: true,
    hideMarkers: false,
};

const Guides = ({ bounds, minTime, maxTime }) => bounds
    .map((bound, index) => {
        const color = [0, 3].indexOf(index) > -1 ? colors.error : colors.brandOne;
        return (
            <Guide
                // eslint-disable-next-line react/no-array-index-key
                key={`key${bound}${index}`}
                type="line"
                start={[minTime, bound]}
                end={[maxTime, bound]}
                lineStyle={{
                    stroke: color,
                    lineDash: [1, 1]
                }}
            />
        );
    });

class RunChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            range: undefined,
            averages: undefined,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { loading } = props;
        let { data } = props;

        if (!loading && !state.range && !state.averages) {
            if (!data.length) {
                data = [{ time: 1, average: 40, min: 40, max: 40 }];
            }
            const averages = data.map(item => ({ time: item.time, CO2: item.average }));
            const range = data.map(item => ({ time: item.time, CO2: [item.min, item.max] }));
            return { range, averages, loaded: true };
        }
        return null;
    }

    render() {
        const { range, averages } = this.state;
        const { loading, id } = this.props;

        if (loading) {
            return <Spin className="center" style={{ marginTop: 100 }} />;
        }

        const times = averages.map(item => item.time);
        const config = Array.filterFirst(sensorConfig, item => item.id === id);
        const minTime = Math.min(...times);
        const maxTime = Math.max(...times);
        const { minScale, maxScale } = config;

        const scale = [
            {
                dataKey: 'CO2',
                sync: false,
                formatter: co2Formatter,
                min: minScale,
                max: maxScale,
            },
            {
                dataKey: 'time',
                min: 0,
                maxLimit: maxTime,
                formatter: timeFormatter,
            }
        ];

        return (
            <Chart
                forceFit
                height={220}
                scale={scale}
                paddding={[30, 10, 0, 10]}
            >
                <Tooltip {...tooltipOpts} />
                <Axis />

                <View data={averages}>
                    <Axis show={false} />
                    <Line position="time*CO2" size={1} />
                    <Guides
                        bounds={config.bounds}
                        minTime={minTime}
                        maxTime={maxTime}
                    />
                </View>
                <View data={range}>
                    <Area
                        position="time*CO2"
                        tooltip={false}
                        style={areaStyle}
                    />
                </View>
            </Chart>
        );
    }
}

RunChart.propTypes = {
    data: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    loading: PropTypes.bool
};

RunChart.defaultProps = {
    loading: true
};

export default RunChart;
