import axios from 'axios';
import { token } from './local.service';

// eslint-disable-next-line import/no-cycle
import { dispatchLogout } from '../reducers';

const BaseUrl = process.env.REACT_APP_API;
const PostmanApiKey = process.env.REACT_APP_POSTMAN_API_KEY;
const AuthPrefix = 'Bearer ';

const api = axios.create({
    baseURL: BaseUrl,
    headers: {
        Accept: 'application/json',
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'x-api-key': PostmanApiKey,
        'Cache-Control': 'no-cache, no-store'
    },
    transformRequest: data => JSON.stringify(data),
    timeout: 10000000,
    responseType: 'json',
});

api.download = (url, data, method) => api({
    method: method || 'post',
    url,
    responseType: 'blob',
    data
});

const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
    }
    return '';
};

api.interceptors.request.use( 
    (config) => {
        const headers = {
            Authorization: `${AuthPrefix}${token()}`,
            'Custom-Tests-Token': getCookie('Custom-Tests-Token')
        };
        return {
            ...config, 
            headers: { 
                ...config.headers,
                ...headers
            }
        };
    }, error => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => {
        if (response.headers['content-type'] === 'application/octet-stream') {
            const disposition = response.headers['content-disposition'] || '';
            const filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition)[1];
            return {
                file: response.data,
                filename
            };
        }
        const authHeader = response.headers.authorization;
        if (authHeader) {
            const authToken = authHeader.substr(AuthPrefix.length);
            token(authToken);
        }
        if (response.data) { 
            return response.data;
        }
        return response;
    }, 
    (error) => {
        const response = error.response || {};
        const responseError = response.data;
        const code = response.status;
        
       if (code === 401) {
            // The token is invalid
            dispatchLogout();
        }
        const err = { code };
        if (error.message) {
            err.message = error.message;
            err.description = error.description || '';    
        } 

        if (responseError) {
            if (responseError.messages) {
                err.message = Object.values(responseError.messages).join(' ');
            } else if (responseError.message) {
                err.message = responseError.message;
            } else {
                err.message = responseError.error;
            } 
        }
    
        if (!err.message) {
            err.message = JSON.stringify(error);
        }        
       
        return Promise.reject(err);
    }
);

 export default api;
