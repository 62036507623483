/* eslint-disable import/no-cycle */
/* eslint-disable prefer-promise-reject-errors */
import api from './_api';

const transformerIn = data => data;

const login = credentials => api
    .post('/token', credentials)
    .then(data => transformerIn(data))
    .catch((error) => {
        if (error.code === 401) {
            return Promise.reject({ message: 'Invalid E-mail or Password' });
        }
        return Promise.reject(error);
    });

const logout = () => api
    .delete('/token')
    .catch(error => Promise.reject(error));

const validate = data => api
    .post('/user/unlock', data);

const get = id => api
    .get(`/users/${id}`)
    .then(res => transformerIn(res));

const getLoggedUser = () => get('me');

const update = (id, data) => api
    .put(`/users/${id}`, data)
    .then(res => transformerIn(res));

const remove = id => api
    .delete(`/users/${id}`);

// const updateLoggedUser = data => update('me', data);

const updateLoggedUser = data => api.put('/clients/mc', data).then(res => transformerIn(res));

export const AuthService = {
    get,
    update,
    remove,
    login,
    logout,
    validate,
    getLoggedUser,
    updateLoggedUser,
};
