import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal } from 'antd';
import { DepopActions as actions } from '../../../actions';
import { depopStatus } from '../../../models';

const { TextArea } = Input;

const ApproveButton = ({ id, onApprove, type, children }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [comment, setComment] = useState('');

    const handleOk = () => {
        setConfirmLoading(true);
        actions.update(id, { status: depopStatus.APPROVED, comment })
            .then(
                (data) => {
                    setConfirmLoading(false);
                    setVisible(false);
                    onApprove(data);
                },
                () => setConfirmLoading(false)
            );
    };

    return (
        <>
            <Modal
                title="Add Comment"
                visible={visible}
                onOk={handleOk}
                onCancel={() => setVisible(false)}
                okText="Approve"
                cancelText="Cancel"
                confirmLoading={confirmLoading}
            >
                <TextArea
                    disabled={confirmLoading}
                    rows={10}
                    onChange={e => setComment(e.target.value)}
                    placeholder="Add your comment here"
                />
            </Modal>
            <Button
                type={type}
                onClick={() => setVisible(true)}
            >
                {children}
            </Button>
        </>
    );
};

ApproveButton.propTypes = {
    id: PropTypes.any.isRequired,
    onApprove: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    children: PropTypes.any
};

ApproveButton.defaultProps = {
    children: null
};

export default ApproveButton;
