import React from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Button, Icon, Modal } from 'antd';
import { ClientActions as actions } from '../../../actions';
import { history, sortFn } from '../../../helpers';
import ClientPreview from './clients.preview';
import RouteWithSider from '../sider';
import { Card } from '../../../containers';
import FormNewClient from './client.new';

const columns = [
    {
        title: '',
        dataIndex: 'id',
        render: () => null
    },
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: sortFn.string('name')
    },
    
    {
        title: '# of farms',
        dataIndex: 'farmsNum',
        sorter: sortFn.number('farmsNum')
    },
    {
        title: '# of assigned machines',
        dataIndex: 'mashinesNum',
        sorter: sortFn.number('mashinesNum')
    },
    {
        title: '# of uploaded runs',
        dataIndex: 'runsNum',
        sorter: sortFn.number('runsNum')
    },
];

const changeClient = id => history.push(`/clients/preview/${id}`);
const gotoDetails = id => history.push(`/clients/${id}`);

class ClientsRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            visible: false
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        actions.list().then(data => this.setState({ data, loading: false }));
    }

    getRowClassName = (run) => {
        const { params: { id } } = this.props;
        if (run.id.toString() === id) {
            return 'ant-table-row-selected';
        }
        return null;
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onRow = client => ({
        onClick: () => changeClient(client.id),
        onDoubleClick: () => gotoDetails(client.id)
    });

    render() {
        const { params: { id } } = this.props;
        const { data, loading, visible } = this.state;

        let client = {};
        if (id) {
            client = Array.filterFirst(data, item => item.id.toString() === id) || {};
        }
        return (
            <RouteWithSider>
                <Row>
                    <Col xs={16}>
                        <Button 
                            type="link right addNew" 
                            onClick={this.showModal}
                            title="Add New Client"
                        >
                            <Icon type="user-add" />
                        </Button>
                        <Modal
                            title="New Client"
                            visible={visible}
                            onCancel={this.handleCancel}
                            footer={null}
                        >
                            <FormNewClient onCancel={this.handleCancel} />
                        </Modal>
                        <Card title="All Clients">
                            <Table
                                loading={loading}
                                rowClassName={this.getRowClassName}
                                rowKey="id"
                                columns={columns}
                                dataSource={data}
                                onRow={this.onRow}
                            />
                        </Card>
                    </Col>
                    {id
                        && (
                            <Col xs={8}>
                                <Card title="Client Details">
                                    <ClientPreview
                                        client={client}
                                    />
                                    <Button
                                        type="primary right"
                                        onClick={() => gotoDetails(client.id)}
                                    >
                                        View Client
                                    </Button>
                                </Card>
                            </Col>
                        )}
                </Row>
            </RouteWithSider>
        );
    }
}

ClientsRoute.propTypes = {
    params: PropTypes.object.isRequired,
};

export default ClientsRoute;
