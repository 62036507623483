import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Form, Divider } from 'antd';
import { localDate, timeFormatter } from '../../../helpers';
import { Item } from '../../../components/form';

const RunDetails = (props) => {
    const { run = {} } = props;
    return (
        <Spin spinning={run === {}}>
            <Form 
                className="readonly"
                layout="horizontal"
                
            >
                <h1>{run.farm && run.farm.name}</h1>
                <Divider />
                <Item label="Barn #">{run.barn}</Item>
                <Item label="Actual run length">{timeFormatter(run.processingTime)}</Item>
                <Item label="Run Upload Date">{localDate(run.createdAt)}</Item>
                <Item label="Run Start Date">{localDate(run.startDate)}</Item>
                <Item label="Run End Date">{localDate(run.finishDate)}</Item>
                <Item label="Production System">{run.productionSystem}</Item>
                <Item label="Hen Type / Breed">{run.henType}</Item>
                <Item label="Est. # of Hens">{run.estimatedNumberOfHens}</Item>
                <Item label="Depop Type">{run.depopType}</Item>
                <Item label="Weeks of Age">{run.weeksOfAge}</Item>
                <Item label="Hens / Cart">{run.hensPerCart || 'N/A'}</Item>
                
                <Item label="Processed Hens">{run.processedHens || 'N/A'}</Item>
                <Item label={(<div>CO<sub>2</sub> per hen</div>)}>{run.CO2PerHen || 'N/A'} g</Item>
                <Item label="Percent in Range">{run.percentInRange || 'N/A'}</Item>
                <Item label="Depop Duration">N/A</Item>
                
            </Form>
        </Spin>
    );
};

RunDetails.propTypes = {
    run: PropTypes.object.isRequired
};

export default RunDetails;
