import { notification } from 'antd';
import { handleError } from './helpers';
// eslint-disable-next-line import/no-cycle
import { DepopService as service } from '../services';

const create = data => service.create(data)
    .catch(handleError);

const list = (...args) => service.list(...args)
    .catch(handleError);

const assign = (...args) => service.assign(...args)
    .then(
        (res) => {
            notification.success({ message: 'The selected runs have been assigned successfully' });
            return res;
        },
        handleError
    );

const unassign = (id, ...args) => service.unassign(id, ...args)
    .then(
        (res) => {
            notification.success({ message: 'The selected runs have been unassigned successfully' });
            return res;
        },
        handleError
    );

const get = id => service.get(id)
    .catch(handleError);

const update = (id, ...args) => service.update(id, ...args)
    .then(
        data => data,
        handleError
    );

const certificate = id => service.certificate(id)
    .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Certificate-${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        },
        (e) => {
            if (e.code === 400) {
                handleError({ message: 'The Certificate could not be found.' });
            } else handleError(e);
        });

export const DepopActions = {
    create,
    list,
    assign,
    unassign,
    update,
    get,
    certificate
};
