import './index.scss';
import React from 'react';
import { Layout } from 'antd';
import ProtectedRoutes from '../../components/security';
import { Header, Footer } from '../../containers';
import { Roles } from '../../models';
import Profile from './profile';
import Dashboard from './dashboard';
import NotFound from '../public/notFound';
import { Runs, Run } from './runs';
import { Clients, Client } from './clients';
import { Depops, Depop } from './depop';
import { Machines } from './machines';
import { UnassignedRuns } from './unassignedRuns';

const { Content } = Layout;

const AdminRoutesConfig = [
    { 
        path: '/profile',
        exact: true,
        main: Profile,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    { 
        path: '/dashboard',
        exact: true,
        main: Dashboard,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    { 
        path: ['/depops', '/depops/preview/:depopId'], 
        exact: true,
        main: Depops,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    { 
        path: ['/depops/:depopId', '/depops/:depopId/runs/:runId'],
        exact: true,
        main: Depop,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    { 
        path: ['/machines'],
        exact: true,
        main: Machines,
        access: [Roles.ADMIN],
    },
    { 
        path: ['/unassigned-runs', '/unassigned-runs/:runId', '/unassigned-runs/:runId/preview'], 
        exact: true,
        main: UnassignedRuns,
        access: [Roles.ADMIN, Roles.CLIENT],
    }, 
    { 
        path: ['/runs', '/runs/preview/:runId'], 
        exact: true,
        main: Runs,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    { 
        path: '/runs/:id',
        exact: true,
        main: Run,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    { 
        path: ['/clients', '/clients/preview/:id'],
        exact: true,
        main: Clients,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    { 
        path: '/clients/:id',
        exact: false,
        main: Client,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    { 
        path: '/*',
        exact: true,
        main: NotFound,
        access: Roles.ALL,
    },
];

const AdminDefault = () => (
    <Layout className="layout">
        <Header />
        <Content>
            <ProtectedRoutes 
                routes={AdminRoutesConfig}
            />
        </Content>
        <Footer />
    </Layout>
);

export default AdminDefault;
