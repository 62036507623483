import React from 'react';
import PropTypes from 'prop-types';
import { Axis, Chart, Coord, Legend, StackBar, Tooltip } from 'viser-react';
import DataSet from '@antv/data-set';
import * as colors from '../../../assets/_variables.scss';

const RangeTypes = {
    op: { name: 'Optimal', color: colors.seaGreen },
    in: { name: 'In Range', color: colors.brandOne },
    out: { name: 'Out of Range', color: colors.error }
};

const getRange = (name) => {
    const key = Object.keys(RangeTypes)
        .filter(item => RangeTypes[item].name === name)[0];
    return RangeTypes[key];
};

const barsColor = ['range', r => getRange(r).color];

const scale = [
    {
        dataKey: 'type',
    },
    {
        dataKey: 'percent',
        alias: 'blaga',
        formatter: value => `${value}%`,
        min: 0,
        max: 100,
        ticks: [],
    }
];

const RunDetailsCharts = (props) => {
    const { run = {}, legend, sensors, labelOnTop } = props;

    const padding = labelOnTop ? [0, 15, 0, 15] : [30, 10, 40, 127];
    const labelY = labelOnTop ? { offsetY: -20, offsetX: 120 } : { offset: 20 };
    const height = sensors.length * (65 + (labelOnTop ? 20 : 0));

    const stages = {
        [RangeTypes.op.name]: {
            0: run.stage4InOptimalRange,
            1: run.stage3InOptimalRange,
            2: run.stage2InOptimalRange,
            3: run.stage1InOptimalRange,
            4: run.inOptimalRange,
        },
        [RangeTypes.in.name]: {
            5: run.stage4InAcceptableRange,
            6: run.stage3InAcceptableRange,
            7: run.stage2InAcceptableRange,
            8: run.stage1InAcceptableRange,
            9: run.inAcceptableRange,
        },
        [RangeTypes.out.name]: {
            10: run.stage4OutOfRange,
            11: run.stage3OutOfRange,
            12: run.stage2OutOfRange,
            13: run.stage1OutOfRange,
            14: run.outOfRange,
        },
    };

    const sourceData = [
        {
            key: 4,
            type: '% in range stage 4',
            [RangeTypes.op.name]: run.stage4InOptimalRange,
            [RangeTypes.in.name]: run.stage4InAcceptableRange,
            [RangeTypes.out.name]: run.stage4OutOfRange
        },
        {
            key: 3,
            type: '% in range stage 3',
            [RangeTypes.op.name]: run.stage3InOptimalRange,
            [RangeTypes.in.name]: run.stage3InAcceptableRange,
            [RangeTypes.out.name]: run.stage3OutOfRange
        },
        {
            key: 2,
            type: '% in range stage 2',
            [RangeTypes.op.name]: run.stage2InOptimalRange,
            [RangeTypes.in.name]: run.stage2InAcceptableRange,
            [RangeTypes.out.name]: run.stage2OutOfRange
        },
        {
            key: 1,
            type: '% in range stage 1',
            [RangeTypes.op.name]: run.stage1InOptimalRange,
            [RangeTypes.in.name]: run.stage1InAcceptableRange,
            [RangeTypes.out.name]: run.stage1OutOfRange
        },
        {
            key: 0,
            type: '% in range average',
            [RangeTypes.op.name]: run.inOptimalRange,
            [RangeTypes.in.name]: run.inAcceptableRange,
            [RangeTypes.out.name]: run.outOfRange
        },
    ];
    const sensorsSource = sourceData.filter(item => sensors.indexOf(item.key) > -1);
    const dv = new DataSet.View().source(sensorsSource);

    const labelX = ['percent*range', (percent, range, obj, key) => {
        const { color } = getRange(range);
        let offsetX = -10;

        if (range === RangeTypes.op.name && percent < 5 && stages[RangeTypes.in.name][key + 5] < 5) {
            offsetX = -13;
        }

        if (range === RangeTypes.in.name && percent < 5 && stages[RangeTypes.op.name][key - 5] < 5) {
            offsetX = -7;
        }

        if (range === RangeTypes.in.name && percent < 5 && stages[RangeTypes.out.name][key + 5] < 5) {
            offsetX = -16;
        }

        return {
            position: 'middle',
            offsetX,
            offsetY: 10,
            textStyle: {
                fontSize: 11,
                fontWeight: 600,
                fill: color,
            }
        };
    }];

    dv.transform({
        type: 'fold',
        fields: [RangeTypes.op.name, RangeTypes.in.name, RangeTypes.out.name],
        key: 'range',
        value: 'percent',
        retains: ['type'],
    });

    const data = dv.rows;
    return (
        <Chart
            forceFit
            data={data}
            scale={scale}
            height={height}
            padding={padding}
        >
            {legend && <Legend position="top-left" />}
            <Coord type="rect" direction="LB" />
            <Tooltip />
            <Axis
                dataKey="type"
                position="right"
                label={labelY}
            />
            {legend && (
                <h4 style={{ paddingTop: 20, marginBottom: 0, fontSize: 11 }}> Legend: </h4>
            )}
            <StackBar
                position="type*percent"
                color={barsColor}
                label={labelX}
                size={12}
                opacity={1}
            />
        </Chart>
    );
};

RunDetailsCharts.propTypes = {
    run: PropTypes.object.isRequired,
    legend: PropTypes.bool,
    sensors: PropTypes.array,
    labelOnTop: PropTypes.bool,
};

RunDetailsCharts.defaultProps = {
    legend: false,
    labelOnTop: false,
    sensors: [0, 1, 2, 3, 4]
};

export default RunDetailsCharts;
