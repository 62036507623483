import './certificate.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Divider, Row, Spin } from 'antd';
import { DepopActions as actions } from '../../actions';
import { localDate } from '../../helpers';
import BarCharts from '../admin/depop/depop.barcharts';
import DepopMetrics from '../admin/depop/depop.metrics';
import DepopPreview from '../admin/depop/depop.preview';
import RunCertificate from './runCertificate';
import { LocalService } from '../../services';

const Header = ({
                    certificateNumber,
                    farm,
                    finishDate,
                    runMetrics,
                    client
                }) => (
    <Row>
        <Col span={24}>
            <div className="right cert-num">
                Certificate confirmation #:
                <div>{certificateNumber}</div>
            </div>
            <div>
                {
                    client.logo && (
                        <img src={client.logo} alt="Logo" className="left" />
                    )
                }
                <h2>{farm && farm.name}</h2>
                <h4>Event Date: {localDate(finishDate)}</h4>
            </div>
            <Divider />
            <p>
                Humane Euthanasia Network has reviewed data from the
                <b> depop event</b>,
                authenticated the results, and assessed a Euthanasia Score of
                <b> {runMetrics.inRange}% </b> for humane euthanasia.
                The multi-stage CO2 euthanasia process was monitored 100% of the time and data
                collected with HEN’s proprietary software was encrypted to ensure
                integrity of the third-party certification.
                The humane euthanasia score is based on scientific
                research of hen euthanasia and hen aversion to CO2 <a href="#C1">[1]</a><a href="#C2">[2]</a>.
            </p>
            <Divider />
            <h3 className="right">% in range: <span className="green">{runMetrics.inRange}% </span></h3>
            <h3>Processed hens: <span className="green">{runMetrics.processedHens}</span></h3>
        </Col>
    </Row>
);

Header.propTypes = {
    certificateNumber: PropTypes.string,
    farm: PropTypes.object,
    finishDate: PropTypes.string,
    runMetrics: PropTypes.object,
    client: PropTypes.object
};

Header.defaultProps = {
    certificateNumber: '',
    farm: {},
    finishDate: '',
    runMetrics: {},
    client: {}
};

const Footer = () => (
    <>
        <Divider style={{ marginTop: 10 }} />
        <Row>
            <Col span={8}>
                <h4>Humane Euthanasia Network, Inc.(HEN)</h4>
                <h4>374 South 600 West, Heyburn, ID 83336</h4>
            </Col>
            <Col span={8} push={8}>
                <h4 className="right">
                    www.hensleeper.com
                </h4>
            </Col>
        </Row>
    </>
);

class DepopCertificate extends React.Component {
    loadedRuns = 0;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            depop: {
                runMetrics: {},
                runs: [],
                client: {},
                loading: true,
            },
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        const { params: { token } } = this.props;
        if (token) {
            LocalService.token(token);
        }
    }

    async componentDidMount() {
        const { params: { id } } = this.props;
        const depop = await actions.get(id)
            .finally(() => this.setState({ loading: false }));
        this.setState({ depop, runsCount: depop.runs.length });
    }

    takeShot = () => {
        if ('callPhantom' in window) {
            window.callPhantom('takeShot');
        }
    }

    handleRunLoad = () => {
        this.loadedRuns += 1;
        const { runsCount } = this.state;
        if (this.loadedRuns === runsCount) {
            setTimeout(this.takeShot, 3000);
        }
    }

    render() {
        const { loading, depop } = this.state;
        const gutter = [20, 20];
        return (
            <div className="certificate">
                <Spin spinning={loading}>
                    <Row gutter={gutter}>
                        <Col span={24} className="logo-holder">
                            <div className="logo" />
                        </Col>
                        <Col span={24}>
                            <h1>Certificate of Humane Euthanasia</h1>
                        </Col>
                        <Col span={24} className="cert-header">
                            <div className="cardc">
                                <Header {...depop} />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={gutter}>
                        <Col span={12}>
                            <div className="cardc depop-details">
                                <DepopPreview
                                    depop={depop}
                                    view="certificate"
                                    loading={loading}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="cardc depop-details">
                                <h2>Depop Metrics</h2>
                                <Divider />
                                <DepopMetrics
                                    metrics={depop.runMetrics || { loading: false }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="cardc">
                                <h2>Depop performance</h2>
                                <Divider />
                                <BarCharts
                                    data={depop.runMetrics || { loading: false }}
                                    legend
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={gutter}>
                        <Col span={24}>
                            <ol>
                                <li id="C1">Iowa State University, Veterinary Diagnostic & Production Animal Medicine,
                                    {/* eslint-disable-next-line max-len */}
                                    Biomedical Sciences. Donna Zellner, Rebecca Parsons, Yuko Sato, Alejandro Hurtado,
                                    {/* eslint-disable-next-line max-len */}
                                    Suzanne Millman. “Evaluation of Animal Welfare During Laying Hen Depopulation Using
                                    CO2 Hensleeper Device” Aug. 10, 2018.
                                </li>
                                <li id="C2">“Assessment of aversion and time to insensibility to CO2 gas exposure in
                                    {/* eslint-disable-next-line max-len */}
                                    laying hens”. R.M.A.S. Bandara, S. Torrey, R. Parsons, T. Widoski, and Millman,
                                    {/* eslint-disable-next-line max-len */}
                                    University of Guelph, Guelph ON, Canada, Iowa State University, Ames, IA. September
                                    2018.
                                </li>
                            </ol>
                            <Footer />
                        </Col>
                    </Row>
                    {
                        depop.runs.map(run => (
                            <Row key={run.id} className="run-page">
                                <RunCertificate
                                    run={run}
                                    Footer={Footer}
                                    loading={loading}
                                    onLoad={this.handleRunLoad}
                                />
                            </Row>
                        ))
                    }
                </Spin>
            </div>
        );
    }
}

DepopCertificate.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
        token: PropTypes.string
    }).isRequired,
};

export default DepopCertificate;
