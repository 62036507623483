import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Table } from 'antd';
import { Route } from 'react-router-dom';
import { RunActions as actions } from '../../../actions';
import { history, localDate, sortFn } from '../../../helpers';
import RunPreview from './runs.preview';
import RunDetails from './run';
import RouteWithSider from '../sider';
import { UnassignedRunsToolbar } from './runs.toolbar';

const columns = [
    {
        title: 'Farm, Barn #',
        dataIndex: 'farm',
        render: (farm, row) => (
            <div className="left">
                <h3>{(farm && farm.name) || 'Unknown '}{row.barn && `, ${row.barn}`}</h3>
                <div className="table-date">{localDate(row.createdAt)}</div>
            </div>
        ),
        sorter: (a, b) => {
            const c = (a.farm && a.farm.name).toString().toLowerCase();
            const d = (b.farm && b.farm.name).toString().toLowerCase();
            if (c < d) return -1;
            if (c > d) return 1;
            return 0;
        },
    },
    {
        title: 'ID',
        dataIndex: 'id',
        render: value => (value || 'N/A'),
        sorter: sortFn.number('id'),
    },
    {
        title: 'Number of Hens',
        dataIndex: 'processedHens',
        render: value => (value || 'N/A'),
        sorter: sortFn.number('processedHens'),
        width: '100px',
    },
    {
        title: <div>CO<sub>2</sub> per hen (g)</div>,
        dataIndex: 'co2WeightPerHenInGrams',
        render: value => (value || 'N/A'),
        sorter: sortFn.number('co2WeightPerHenInGrams'),
    },
    {
        title: '% in range',
        dataIndex: 'inAcceptableRange',
        render: (value, row) => (value || 0) + (row.inOptimalRange || 0),
        sorter: sortFn.number('inAcceptableRange'),
    },
];

const RunsRoute = ({ params: { runId } }) => {
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(true);

    const load = async () => {
        const response = await actions.listUnassigned()
            .finally(() => setLoading(false));
        setData(response);
    };

    useEffect(() => {
        load();
    }, []);

    const showPreview = id => history.push(`/unassigned-runs/${id}`);
    const showRun = id => history.push(`/unassigned-runs/${id}/preview`);

    // eslint-disable-next-line no-shadow
    const onRow = ({ id }) => ({
        onClick: () => {
            showPreview(id);
        },
        onDoubleClick: () => {
            showRun(id);
        }
    });

    const getRowClassName = run => ((run.id.toString() === runId) ? 'ant-table-row-selected' : null);

    const rowSelection = {
        onChange: keys => setSelectedRowKeys(keys)
    };

    let run = {};
    if (runId) {
        run = data.find(item => item.id.toString() === runId) || {};
    }
    return (
        <RouteWithSider>
            <Route exact path="/unassigned-runs/:runId/preview">
                <RunDetails onClose={showPreview} />
            </Route>
            <Row>
                <Col xs={16}>
                    <div className="card">
                        <h3>
                            Unassigned Runs |
                            <UnassignedRunsToolbar
                                selectedRuns={selectedRowKeys}
                                onSuccess={load}
                            />
                        </h3>
                        <Table
                            rowSelection={rowSelection}
                            loading={loading}
                            rowClassName={getRowClassName}
                            rowKey="id"
                            columns={columns}
                            dataSource={data}
                            onRow={onRow}
                        />
                    </div>
                </Col>
                <Col xs={8}>
                    {runId && (
                        <RunPreview
                            run={run}
                            onDetails={showRun}
                        />
                    )}

                </Col>
            </Row>
        </RouteWithSider>
    );
};

RunsRoute.propTypes = {
    params: PropTypes.shape({
        runId: PropTypes.string,
        clientId: PropTypes.string
    }).isRequired,
};

export default RunsRoute;
