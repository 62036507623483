// eslint-disable-next-line import/no-cycle
import api from './_api';

const Path = '/machines';

const list = () => api
    .get(`${Path}/`)
    .then(({ data }) => data)
    .catch(error => Promise.reject(error));

const get = id => api
    .get(`${Path}/${id}`);

const update = (id, values) => api
    .put(`${Path}/${id}`, values);

const remove = id => api
    .delete(`${Path}/${id}`);
    
const create = data => api
    .post(`${Path}/`, data);

const controllers = unassigned => api
    .get(`/machineControllers${unassigned ? '/unassigned' : ''}`)
    .then(({ data }) => data);

export const MachineService = {
    list,
    get,
    update,
    create,
    remove,
    controllers
};
