import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Divider, Modal, Col, Row, notification, Popconfirm } from 'antd';
import { ClientActions as actions } from '../../../actions';
import { UserModel as model } from '../../../models';
import Form, { FormItem } from '../../../components/form';
import { Secured } from '../../../components/security';

export default class ClientAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
        };
    }

    componentDidMount() {
        const { clientId } = this.props;
        actions.getUsersForClient(clientId)
            .then(
                data => this.setState({ data }),
            );
    }

    toggleModal = () => {
        const { visible } = this.state;
        this.setState({
            visible: !visible,
        });
    };

    hadnleAdd = (values) => {
        const { data } = this.state;
        const { clientId } = this.props;
        return actions.addUserToClient({ ...values, clientId })
            .then((res) => {
                data.push(res.user);
                this.setState({ visible: false, data });
            });
    };

    handleDelete = (userId) => {
        const { clientId } = this.props;
        return actions.deleteUserFromClient(userId, clientId)
            .then(() => {
                const { data } = this.state;
                data.splice(data.findIndex(e => e.id === userId), 1);
                this.setState({
                    data
                });
                notification.success({ message: 'User deleted!' });
        });
    };

    render() {
        const { visible, data } = this.state;

        return (
            <div className="customer-accounts-form">
                <Modal
                    title="New Customer"
                    visible={visible}
                    onCancel={this.toggleModal}
                    confirmLoading={false}
                    footer={null}
                >
                    <Form
                        initialValues={{}}
                        onSubmit={this.hadnleAdd}
                    >
                        <FormItem {...model.firstName} />
                        <FormItem {...model.lastName} />
                        <FormItem {...model.email} />
                        <Button
                            type="primary right"
                            htmlType="submit"
                        >Create
                        </Button>
                        <Button
                            type="default right"
                            onClick={this.toggleModal}
                        >Cancel
                        </Button>
                    </Form>
                </Modal>
                {
                    data.map(item => (
                        <div key={item.id} className="account-container">
                            <Row>
                                <Col span={20}>
                                    <Row>
                                        <span className="account-name">{`${item.firstName} ${item.lastName}`}</span>
                                    </Row>
                                    <Row>
                                        {`${item.email}`}
                                    </Row>
                                </Col>
                                <Col span={4}>
                                    <Secured grant="ADMIN">
                                        <Popconfirm 
                                            onConfirm={() => this.handleDelete(item.id)}
                                            title="Are you sure you want to delete customer?" 
                                            okText="Delete"
                                        >
                                            <Icon type="delete" />
                                        </Popconfirm>
                                    </Secured>
                                </Col>
                            </Row>
                            <Divider />
                        </div>
                    ))
                }
                <Secured grant="ADMIN">
                    <Button type="primary right" onClick={this.toggleModal}>
                        <Icon type="plus" /> Add Client
                    </Button>
                </Secured>
            </div>
        );
    }
}

ClientAccounts.propTypes = {
    clientId: PropTypes.string.isRequired,
};
