import { notification } from 'antd';
import { handleError } from './helpers';
// eslint-disable-next-line import/no-cycle
import { RunService as service } from '../services';

const list = (...args) => service
    .list(...args)
    .catch(handleError);

const listUnassigned = (...args) => service
    .listUnassigned(...args)
    .catch(handleError);

const listByClient = (...args) => service
    .listByClient(...args)
    .catch(handleError);

const get = id => service
    .get(id)
    .catch(handleError);

const getData = id => service
    .getData(id)
    .catch(handleError);

const update = (...args) => service
    .update(...args)
    .catch(handleError);

const recalculate = (...args) => service
    .recalculate(...args)
    .catch(handleError);

const remove = id => service
    .remove(id)
    .then(() => {
        notification.success({ message: 'Run(s) deleted!' });
    })
    .catch(handleError);

export const RunActions = {
    list,
    listUnassigned,
    listByClient,
    get,
    getData,
    update,
    recalculate,
    remove,
};
