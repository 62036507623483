import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Divider, Form } from 'antd';
import { Item } from '../../../components/form';

const RunCharacteristics = ({
                                farm, barn, henType, henWeight,
                                depopType, hensPerCart, machine,
                                depopEvent
                            }) => {
    const depopStartDate = depopEvent && moment(depopEvent.startDate).format('YYYYMMDD');
    const depopFarmName = depopEvent && depopEvent.farm && depopEvent.farm.name;
    const depopBarn = depopEvent && depopEvent.barn;
    const depopName = depopEvent ? `${depopStartDate}-${depopFarmName}-${depopBarn}` : 'N/A';
    return (
        <Form
            className="readonly"
            layout="horizontal"

        >
            <h3>Run Characteristics</h3>
            <Divider />

            <Item label="Farm">{farm && farm.name}</Item>
            <Item label="Barn #">{barn}</Item>
            <Item label="Hen Type / Breed">{henType}</Item>
            <Item label="Hen Weight">{henWeight}</Item>
            <Item label="Depop Event">{depopName}</Item>
            <Item label="Depop Type">{depopType}</Item>
            <Item label="Hens per Cart">{hensPerCart}</Item>
            <Item label="Machine ID">{machine && machine.serialNumber}</Item>
        </Form>
    );
};

RunCharacteristics.propTypes = {
    farm: PropTypes.object,
    barn: PropTypes.number,
    henType: PropTypes.string,
    henWeight: PropTypes.any,
    depopType: PropTypes.string,
    hensPerCart: PropTypes.number,
    machine: PropTypes.object,
    depopEvent: PropTypes.object
};

RunCharacteristics.defaultProps = {
    farm: { name: '' },
    barn: null,
    henType: '',
    henWeight: '',
    depopType: '',
    hensPerCart: 0,
    machine: { name: '' },
    depopEvent: {}
};

export default RunCharacteristics;
