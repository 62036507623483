// eslint-disable-next-line import/no-cycle
import api from './_api';

const PathDepop = '/depops';

const create = data => api
    .post(`${PathDepop}`, data);

const list = (clientId) => {
    const listPath = clientId ? `depops?client=${clientId}` : PathDepop;

    return api.get(listPath)
        .then(data => data.data)
        .catch(error => Promise.reject(error));
};

const assign = (id, runs) => api
    .put(`${PathDepop}/${id}/runs`, runs);

const unassign = (id, runs) => api
    .delete(`${PathDepop}/${id}/runs`, {
        data: { runs }
    });

const get = id => api
    .get(`${PathDepop}/${id}`).then(data => data);

const update = (id, values) => api
    .put(`${PathDepop}/${id}`, values);

const certificate = id => api
    .download(`${PathDepop}/${id}/certificate`, null, 'GET');

export const DepopService = {
    create,
    list,
    assign,
    unassign,
    get,
    update,
    certificate
};
