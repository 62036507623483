import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input } from 'antd';
import { MachineActions as actions } from '../../../actions';

const MachineNew = ({ visible, onSuccess, onCancel }) => {
    const [serialNumber, setSerialNumber] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
      
    const handleChange = ({ target: { value } }) => {
        setSerialNumber(value);
        setError(value ? false : 'Serial Number is required');
    };

    const handleSave = async () => {
        if (!serialNumber) {
            setError('Serial Number is required');
            return;
        }
        setLoading(true);
        await actions.create({ serialNumber })
            .finally(() => {
                setLoading(false);
            });
        
        setError(false);
        setSerialNumber();
        onSuccess();
    };

    const handleCancel = () => {
        setLoading(false);
        setError(false);
        setSerialNumber();
        onCancel();
    };

    return (
        <Modal
            title="New Machine"
            visible={visible}
            confirmLoading={loading}
            onOk={handleSave}
            onCancel={handleCancel}
            okText="Create"
        >
            <Form.Item 
                label="Please enter the new machine SN"
                validateStatus={error ? 'error' : ''}
                help={error}
            >
                <Input 
                    value={serialNumber} 
                    onChange={handleChange}
                />
            </Form.Item>
        </Modal>
    );
};

MachineNew.propTypes = {
    visible: PropTypes.bool.isRequired, 
    onSuccess: PropTypes.func.isRequired, 
    onCancel: PropTypes.func.isRequired, 
};

export default MachineNew;
