import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { message, Upload } from 'antd';
// eslint-disable-next-line import/no-cycle
import { LocalService } from '../../../services';

const { Dragger } = Upload;

class FileUpload extends Component {
    handleChange = (info) => {
        const { status } = info.file;
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        const { onChange } = this.props;
        if (onChange) {
            onChange(info.fileList);
        }
    }

    render() {
        const { action, accept, name, multiple } = this.props;
        const thisProps = {
            action,
            accept,
            name,
            multiple,
            onChange: this.handleChange,
            headers: {
                Authorization: `Bearer ${LocalService.token()}`
            },
        };
        return (
            <Dragger {...thisProps}>
                <p className="ant-upload-text">
                    Click or drag file to this area to upload
                </p>
            </Dragger>
        );
    }
}

FileUpload.propTypes = {
    action: PropTypes.string.isRequired,
    accept: PropTypes.string,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func
};

FileUpload.defaultProps = {
    name: 'file',
    multiple: true,
    accept: '.als, .cer, .csv, .dat, .doc, .docx, .epl, .exe, .gif, .ico, .ics, .jpeg, .jpg, .js, .mdb, .msi, .ofx, .pdf, .pfx, .png, .ppt, .pptx, .qbo, .qfx, .rar, .rtf, .sql, .swf, .tif, .txt, .xls, .xlsx, .xml, .zip, .zpl',
    onChange: () => {
    }
};

export { FileUpload };
