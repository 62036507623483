import { message } from 'antd';

export const handleError = (error) => {
    if (error && error.message) {
        message.error(`${error.source || ''} ${error.message} ${error.description || ''}`, 3);
    }
    return Promise.reject(error);
}; 

export const showMessage = (text, sec) => {
    message.success(text, sec || 3);
};
