import './navigation.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const { Item } = Menu;

const Navigation = ({ current }) => (
    <Menu 
        className="navigation"    
        theme="light"
        selectedKeys={[current]} 
        mode="horizontal"
    >
        <Item key="/mission">
            <Link to="/mission">Mission</Link>
        </Item>
        <Item key="/science">
            <Link to="/science">The Science</Link>
        </Item>
        <Item key="/products">
            <Link to="/products">Products & Certification</Link>
        </Item>
        <Item key="/members">
            <Link to="/members">Network Members</Link>           
        </Item>
        <Item key="/about">
            <Link to="/about">About Us</Link>
        </Item>
    </Menu>
);

Navigation.propTypes = {
    current: PropTypes.string
};

Navigation.defaultProps = {
    current: ''
};

export default Navigation;
