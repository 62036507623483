export const toQueryParams = params => Object.keys(params)
    .map((k) => {
        let value = params[k];
        if (Object.isObject(value)) {
            if (Object.isEmpty(value)) {
                value = '';
            } else value = JSON.stringify(value);
        }
        if (value) {
            return `${k}=${value}`;
        }
        return '';
    })
    .join('&');

export const getHashValue = (key) => {
    const matches = window.location.hash.match(new RegExp(`${key}=([^&]*)`));

    return matches ? matches[1] : null;
};

export const getQueryStringValue = (key) => {
    const encodedKey = encodeURIComponent(key).replace(/[.+*]/g, '\\$&');
    const re = new RegExp(`^(?:.*[&\\?]${encodedKey}(?:\\=([^&]*))?)?.*$`, 'i');
    return decodeURIComponent(window.location.search.replace(re, '$1'));
};
