import './Form.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Form, Modal, Spin } from 'antd';
import FormContext from './FormContext';

export { FormItem } from './FormItem';

const FormNavigationPrompt = ({ show, save }) => (
    <NavigationPrompt when={show}>
        {({ onConfirm }) => {
            const onOk = () => {
                save(null, onConfirm);
            };

            return (
                <Modal
                    title="You are about to leave page"
                    visible
                    onOk={onOk}
                    onCancel={onConfirm}
                    okText="Save and exit"
                    cancelText="Exit without saving"
                >
                    <p>Without saving all filled data will be lost.</p>
                </Modal>
            );
        }}
    </NavigationPrompt>
);

FormNavigationPrompt.propTypes = {
    save: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

class FormWithContext extends Component {
    unmounted = false;

    constructor(props) {
        super(props);
        this.form = props.form;
        this.state = { spinning: false };
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    validateFields = (names, callback) =>
        this.form.validateFields(names, { force: true }, callback);

    getFieldValue = name => this.form.getFieldValue(name);

    getFieldsValue = () => this.form.getFieldsValue();

    setFieldsValue = fields => this.form.setFieldsValue(fields)

    submit = () => this.handleSubmit()

    resetFields = (...args) => this.form.resetFields(...args);

    handleSubmit = (e, after) => {
        if (e) e.preventDefault();
        this.form.validateFields((err, values) => {
            if (!err) {
                if (this.props.spin) this.setState({ spinning: true });
                const result = this.props.onSubmit(values);
                if (result) {
                    return Promise.resolve(result).finally(() => {
                        if (this.unmounted) return;
                        if (this.props.spin) this.setState({ spinning: false });
                        this.form.resetFields();
                        if (after) after();
                    });
                }
            }
            return Promise.resolve(values);
        });
    }

    onKeyDown = e => (e.keyCode === 13 ? e.preventDefault() : '')

    render() {
        const {
            form, layout, hideRequiredMark,
            spin, initialValues, submitOnEnter,
            disabled, children,
            confirmOnLeave
        } = this.props;

        const { spinning } = this.state;
        const formIsChanged = this.form.isFieldsTouched();
        return (
            <Spin spinning={spin && (initialValues === undefined || spinning)}>
                {confirmOnLeave && (
                    <FormNavigationPrompt
                        show={formIsChanged}
                        save={this.handleSubmit}
                    />
                )}

                <Form
                    disabled={disabled}
                    noValidate
                    layout={layout}
                    hideRequiredMark={hideRequiredMark}
                    onSubmit={this.handleSubmit}
                    onKeyDown={submitOnEnter === false ? this.onKeyDown : undefined}
                >

                    <FormContext.Provider value={{ ...form, initialValues, disabled }}>
                        {children}
                    </FormContext.Provider>
                </Form>
            </Spin>
        );
    }
}

FormWithContext.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    hideRequiredMark: PropTypes.bool,
    layout: PropTypes.string,
    initialValues: PropTypes.object,
    spin: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.any,
    confirmOnLeave: PropTypes.bool
};

FormWithContext.defaultProps = {
    hideRequiredMark: false,
    layout: 'vertical',
    initialValues: undefined,
    spin: true,
    submitOnEnter: true,
    onSubmit: () => {
    },
    disabled: false,
    children: null,
    confirmOnLeave: false,
};

export default Form.create()(FormWithContext);
