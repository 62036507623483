import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, List, Row } from 'antd';
import FarmComponent from './client.farm';
import MachineComponent from './client.machine';
import AccountComponent from './client.account';
import Card from '../../../containers/card/index';
import { ClientActions as actions } from '../../../actions';
import { Secured } from '../../../components';

import Form, { FormItem } from '../../../components/form';
import { ClientModel as model } from '../../../models';

class ClientSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { machines: [] }
        };
    }

    componentDidMount() {
        const { params: { id } } = this.props;
        actions.get(id)
            .then(data => this.setState({ data }));
    }

    handleSubmit = (values) => {
        const { params: { id } } = this.props;
        return actions.update(id, values)
            .then(data => this.setState({ data }));
    }

    render() {
        const { data } = this.state;
        const { params: { id } } = this.props;

        return (
            <>
                <Row>
                    <Col xs={24} sm={24} md={16}>
                        <Card title="Contact Info" bordered={false}>
                            <Form
                                wrappedComponentRef={inst => this.form = inst}
                                initialValues={data}
                                onSubmit={this.handleSubmit}
                                confirmOnLeave
                            >
                                <Secured grant="ADMIN">
                                    <FormItem {...model.clientName} />
                                </Secured>
                                <FormItem {...model.emails} />

                                <Button
                                    type="primary right"
                                    htmlType="submit"
                                >Save
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Card title="Farms" bordered={false}>
                            <FarmComponent clientId={id} />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={16}>
                        <Card title="Machines" bordered={false}>
                            <Secured grant="ADMIN">
                                <MachineComponent clientId={id} />
                            </Secured>
                            <Secured grant="CLIENT">
                                <List
                                    dataSource={data.machines}
                                    renderItem={m => <List.Item>{m.serialNumber || 'N/A'} </List.Item>}
                                />
                            </Secured>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Card title="Customer Accounts" bordered={false}>
                            <AccountComponent clientId={id} />
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

ClientSettings.propTypes = {
    params: PropTypes.object.isRequired,
};

export default ClientSettings;
