import { notification } from 'antd';
import { AuthConstants as constants } from '../constants';

// eslint-disable-next-line import/no-cycle
import { AuthService as service, LocalService } from '../services';
import { history, showResponseError } from '../helpers';

const loginRequest = user => ({ type: constants.LOGIN_REQUEST, user });
const loginSuccess = user => ({ type: constants.LOGIN_SUCCESS, user });
const logoutSuccess = () => ({ type: constants.LOGOUT_SUCCESS });
const loginFailure = error => ({ type: constants.LOGIN_FAILURE, error });
const profileChanged = user => ({ type: constants.PROFILE_CHANGED, user });

const notificationLog = (type, message, description) => {
  notification[type]({
    message,
    duration: 2,
    description
  });
};

const login = credentials => (dispatch) => {
  dispatch(loginRequest(credentials));

  service.login(credentials)
    .then(
      (user) => {
        dispatch(loginSuccess(user));
        history.push('/runs');
        notificationLog('success', 'Logged In...', 'You have been logged in!');
      },
      (error) => {
        dispatch(loginFailure(error));
        showResponseError(error);
      }
    );
};

const validate = data => dispatch => service.validate(data)
  .then(
    user => dispatch(loginSuccess(user)),
    showResponseError
  )
  .then(() => {
    history.push('/runs');
    notification.success({ message: 'Registration completed', duration: 3 });
  });

const logout = () => (dispatch) => {
  const token = LocalService.token();
  if (!token) dispatch(logoutSuccess());

  return service.logout()
    .then(
      () => {
        dispatch(logoutSuccess());
        history.push('/');
        notificationLog('success', 'Logged Out...', 'You have been logged out!');
      },
      () => dispatch(logoutSuccess())
    );
};

const getLoggedUser = () => (dispatch) => {
  const token = LocalService.token();
  if (!token) {
    return Promise.resolve();
  }

  dispatch(loginRequest({}));
  return service.getLoggedUser()
    .then(
      data => dispatch(profileChanged(data)),
      error => dispatch(loginFailure(error)),
    );
};

const updateLoggedUser = values => dispatch =>
  service.updateLoggedUser(values)
    .then(
      (data) => {
        dispatch(profileChanged(data));
        notificationLog('success', 'Profile Saved');
        return data;
      },
      error => showResponseError(error)
    );

const remove = id => service.remove(id)
  .then(
    data => data,
    error => showResponseError(error)
  );

export const AuthActions = {
  login,
  logout,
  loginSuccess,
  logoutSuccess,
  validate,
  getLoggedUser,
  updateLoggedUser,
  remove,
};
