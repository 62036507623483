import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Select } from 'antd';
import { MachineActions as actions } from '../../../actions';

const { Option } = Select;

const MachineEdit = ({ machine, onSuccess, onCancel }) => {
    const [machineControllers, setMachineControllers] = useState([]);
    const [machineController, setMachineController] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            // eslint-disable-next-line max-len
            const defaultController = machine && machine.machineController && machine.machineController.id;
            const result = await actions.controllers(true)
                .finally(() => setLoading(false));

            if (defaultController) {
                result.push(machine.machineController);
            }
            setMachineController(defaultController);
            setMachineControllers(result);
        };
        if (machine) fetch();
    }, [machine]);

    const handleSave = async () => {
        setLoading(true);
        const { id } = machine;
        await actions.update(id, { machineController })
            .finally(() => setLoading(false));
        onSuccess();
    };

    return (
        <Modal
            title="Edit Machine"
            visible={machine !== undefined}
            confirmLoading={loading}
            onOk={handleSave}
            onCancel={onCancel}
            okText="Save"
        >
            <Form.Item
                label="Please select from the dropdown the Machine Controller to assign to this machine"
            >
                <Select
                    value={machineController}
                    onChange={setMachineController}
                    style={{ width: '100%' }}
                >
                    <Option value={null}>Unassign Machine Controller</Option>
                    {machineControllers.map(item => (
                        <Option
                            key={item.id}
                            value={item.id}
                        >
                            {item.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Modal>
    );
};

MachineEdit.propTypes = {
    machine: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

MachineEdit.defaultProps = {
    machine: undefined
};

export default MachineEdit;
