import { handleError } from './helpers';
// eslint-disable-next-line import/no-cycle
import { MachineService as service } from '../services';

const list = () => service
    .list()
    .then(
        data => data,
        handleError
    );

const get = id => service
    .get(id)
    .then(
        data => data,
        handleError
    );

const create = data => service.create(data)
    .then(
        res => res,
        handleError
    );

const update = (id, ...args) =>
    service.update(id, ...args)
        .then(
            data => data,
            handleError
        );

const remove = id => service.remove(id)
    .then(
        data => data,
        handleError
    );

const controllers = (...args) => service.controllers(...args)
    .then(
        data => data,
        handleError
    );

export const MachineActions = {
    list,
    get,
    update,
    create,
    remove,
    controllers
};
