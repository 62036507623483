import './card.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const Card = ({ children, title, expandable }) => {
    const [expand, setExpand] = useState(true);
    return (
        <div className="card">
            <h3>{title} 
                { expandable && (
                    <Button 
                        onClick={() => setExpand(!expand)} 
                        type="link" 
                        icon={expand ? 'down' : 'up'} 
                    /> 
                )}
            </h3>
            {expand && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
};

Card.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
    expandable: PropTypes.bool
};

Card.defaultProps = {
    expandable: false,
    children: null
};

export default Card;
