import React from 'react';
import PropTypes from 'prop-types';
import { Form, Divider } from 'antd';
import { Item } from '../../../components/form';
import { localTime, timeFormatter } from '../../../helpers';

const RunStatistics = ({ 
    processingTime, startDate, finishDate, processedHens, 
    co2Weight, co2WeightPerHenInGrams, liveHens, efficacy
}) => (
    <Form 
        className="readonly"
        layout="horizontal"
        
    >   
        <h3>Run Statistics</h3>
        <Divider />
        <Item label="Actual Run Length">{timeFormatter(processingTime)}</Item>
        <Item label="Run Start Time">{localTime(startDate)}</Item>
        <Item label="Run End Time">{localTime(finishDate)}</Item>
        <Item label="Processed Hens">{processedHens}</Item>
        <Item label="Live Hens">{liveHens}</Item>
        <Item label="Efficacy">{efficacy ? `${efficacy} %` : ''}</Item>
        <Item label={<>Total CO<sub>2</sub> used</>}>{co2Weight ? `${co2Weight} kg` : 'N/A'} </Item>
        <Item label={<>CO<sub>2</sub> per hen</>}>{co2WeightPerHenInGrams ? `${co2WeightPerHenInGrams} g` : 'N/A'}
        </Item> 
    </Form>
);

RunStatistics.propTypes = {
    processingTime: PropTypes.number, 
    startDate: PropTypes.string, 
    finishDate: PropTypes.string, 
    processedHens: PropTypes.number, 
    co2Weight: PropTypes.number, 
    co2WeightPerHenInGrams: PropTypes.string, 
};

RunStatistics.defaultProps = {
    processingTime: 0, 
    startDate: '', 
    finishDate: '', 
    processedHens: 0, 
    co2Weight: 0, 
    co2WeightPerHenInGrams: '', 
};

export default RunStatistics;
