import React from 'react';
import { Divider, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { MachineActions as actions } from '../../../actions';

const { Option } = Select;

export default class MachineControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
        };
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        this.setState({ loading: true });
        actions.list()
            .then(data => data.filter(item => item.machineController))
            .then(data => this.setState({ data }))
            .finally(() => this.setState({ loading: false }));
    }

    handleAdd = (_id, element) => {
        const { clientId } = this.props;
        const { data } = this.state;
        const arr = [];
        this.setState({ loading: true });
        arr.push(element);
        arr.forEach((el) => {
            const obj = data.find(z => z.id === Number(el.key));
            obj.client = { id: Number(clientId) };
            actions.update(el.key, { client: Number(clientId) })
                .then(() => this.setState({ data }))
                .finally(() => this.setState({ loading: false }));
        });
    };

    handleDelete = (value) => {
        this.setState({ loading: true });
        actions.update(value.key, { client: null })
            .then(() => this.load())
            .finally(() => this.setState({ loading: false }));
    };

    render() {
        const { clientId } = this.props;
        const { data, loading } = this.state;
        // eslint-disable-next-line max-len
        const opt = data.filter(item => !item.client).map((key) => <Option key={key.id}>{key.serialNumber}</Option>);
        const assignedOpt = [];
        data.forEach((key) => {
            if (key.client != null && key.client.id === Number(clientId)) {
                assignedOpt.push({ key: key.id, label: key.serialNumber });
            }
        });

        return (
            <Spin spinning={loading}>
                <h3>Assigned Machines</h3>
                <Divider style={{ marginBottom: 15 }} />
                <Select
                    mode="multiple"
                    labelInValue
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={assignedOpt}
                    onSelect={this.handleAdd}
                    onDeselect={this.handleDelete}
                >
                    {opt}
                </Select>
            </Spin>
        );
    }
}

MachineControl.propTypes = {
    clientId: PropTypes.string.isRequired,
};
