import React from 'react';
import { Row, Col, Carousel, Button } from 'antd';
import { Link } from 'react-router-dom';
import './home.scss';

export default () => (
    <div id="home-content">
        <Carousel autoplay={false}>
            <div>
                <h1>
                    We help egg producers 
                </h1>
                <Link to="/auth/login">
                    <Button type="primary">
                        Read more
                    </Button>
                </Link>
            </div>
        </Carousel>
        <div className="container">
            <Row gutter={32}>
                <Col span={24}><h1>The Science</h1></Col>
                <Col span={11} className="home-content-bar-col">
                    <div className="home-content-bar-col-1">
                        <h3>
                            Independent Study on CO2 poultry euthanasia
                        </h3>
                    </div>
                </Col>
                <Col span={11} offset={2} className="home-content-bar-col">
                    <div className="home-content-bar-col-2">
                        <h3>
                            Independent Study on Hensleeper
                        </h3>
                    </div>
                </Col>
            </Row>
            
            <Row>
                <Col span={24}><h1>Products & Certification </h1></Col>
                <Col span={24} className="home-content-bar-col">
                    <div className="home-content-bar-col-1" />
                </Col>
            </Row>
        </div>
    </div>
);
