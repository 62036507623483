// eslint-disable-next-line import/no-cycle
import { FieldRules, FieldTypes } from '../components/form/Field';

export const depopStatus = {
    IN_PROGRESS: 1,
    PENDING_APPROVAL: 2,
    APPROVED: 3
};

export const statusIconConst = [
    { value: 1, type: 'play-circle' },
    { value: 2, type: 'clock-circle' },
    { value: 3, type: 'check-circle' }
];

export const depopTypeConst = [
    { value: 1, text: 'Standard' },
    { value: 2, text: 'Disease Control' }
];

export const productionSystemsConst = [
    { value: 1, text: 'Cage Free' },
    { value: 2, text: 'Conventional' },
    { value: 3, text: 'Enriched Colony' },
    { value: 4, text: 'Free Range' },
    { value: 5, text: 'Organic' },
    { value: 6, text: 'Other' }
];

export const DepopModel = {
    client: {
        name: 'client',
        displayName: 'Client',
        placeholder: 'Add Client',
        validation: [
            FieldRules.REQUIRED
        ],
        type: FieldTypes.SELECT,
    },
    farmName: {
        name: 'farm',
        displayName: 'Farm',
        placeholder: 'Add Farm Name',
        type: FieldTypes.SELECT,
        validation: [
            FieldRules.REQUIRED
        ],
        disabled: ({ client }) => !client
    },
    farmNameForClient: {
        name: 'farm',
        displayName: 'Farm',
        placeholder: 'Add Farm Name',
        type: FieldTypes.SELECT,
        validation: [
            FieldRules.REQUIRED
        ],
    },
    barnName: {
        name: 'barn',
        displayName: 'Barn',
        placeholder: 'Add Barn Name',
        validation: [
            FieldRules.REQUIRED
        ],
        type: FieldTypes.INPUT,
    },
    comment: {
        name: 'comment',
        displayName: 'Comment',
        placeholder: 'Add Comment',
        validation: [
            FieldRules.REQUIRED
        ],
        type: FieldTypes.TEXTAREA,
    },
    depopStartDate: {
        name: 'startDate',
        displayName: 'Depop Start Date',
        placeholder: 'Depop Start Date',
        validation: [
            FieldRules.REQUIRED,
        ],
        type: FieldTypes.DATE,
    },
    depopType: {
        name: 'type',
        displayName: 'DepopType',
        placeholder: 'Add Depop Type',
        validation: [
            FieldRules.REQUIRED,
        ],
        type: FieldTypes.SELECT,
        options: depopTypeConst
    },
    productionSystems: {
        name: 'productionSystem',
        displayName: 'Production Systems',
        placeholder: 'Add Production System',
        validation: [
            FieldRules.REQUIRED,
        ],
        type: FieldTypes.SELECT,
        options: productionSystemsConst
    },
    henType: {
        name: 'henType',
        displayName: 'Hen Type / Breed',
        placeholder: 'Add Hen Type / Breed',
        validation: [
            FieldRules.REQUIRED,
        ],
        type: FieldTypes.INPUT,
    },
    hensInBarn: {
        name: 'hens',
        displayName: 'Est. Hens in the Barn',
        placeholder: 'Add Hens in Barn',
        validation: [
            FieldRules.REQUIRED
        ],
        type: FieldTypes.NUMBER,
    },
    weeksOfAge: {
        name: 'weeksOfAge',
        displayName: 'Weeks of Age',
        placeholder: 'Add Weeks of Age',
        validation: [
            FieldRules.REQUIRED
        ],
        type: FieldTypes.NUMBER,
    },
};
