import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import RunDetails from '../unassignedRuns/run';
import { history, localDate, timeFormatter } from '../../../helpers';
import { DepopActions as actions } from '../../../actions';
import { depopStatus } from '../../../models';

const columns = [
    {
        title: 'Run ID',
        dataIndex: 'id',
        render: value => value,
    },
    {
        title: 'Completion Date',
        dataIndex: 'finishDate',
        render: value => (localDate(value) || 'N/A'),
    },
    {
        title: 'Actual Run Time',
        dataIndex: 'processingTime',
        render: value => (timeFormatter(value) || 'N/A'),
    },
    {
        title: 'Processed Hens',
        dataIndex: 'processedHens',
        render: value => (value || 'N/A'),
    },
    {
        title: '% in range',
        dataIndex: 'inOptimalRange',
        render: (value, row) => (value + row.inAcceptableRange || 'N/A'),
    },
];

const DepopRuns = ({ depop }) => {
    const [runs, setRuns] = useState(depop.runs);
    const [selectedRuns, setSelectedRuns] = useState([]);
    const [loading, setLoading] = useState(false);

    const load = async () => {
        setLoading(true);
        actions.get(depop.id).then((data) => {
            setRuns(data.runs);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (depop) {
            setRuns(depop.runs);
        }
    }, [depop.runs]);

    const unassignRuns = async () => {
        setLoading(true);
        actions.unassign(depop.id, selectedRuns).then(() => {
            load();
            setLoading(false);
            setSelectedRuns([]);
        });
    };

    const onRunClose = () => history.push(`/depops/${depop.id}`);
    const showRunDetails = id => history.push(`/depops/${depop.id}/runs/${id}`);

    const onRow = run => ({
        onDoubleClick: () => showRunDetails(run.id)
    });

    const rowSelection = {
        selectedRowKeys: selectedRuns,
        onChange: keys => setSelectedRuns(keys),
    };

    const disabled = depop.status === depopStatus.APPROVED || selectedRuns.length === 0;

    return (
        <>
            <Route exact path="/depops/:depopId/runs/:runId">
                <RunDetails onClose={onRunClose} />
            </Route>
            <Button icon="link" type="link" disabled={disabled} onClick={unassignRuns}>Unassign</Button>
            <Table
                rowSelection={depop.status !== depopStatus.APPROVED ? rowSelection : false}
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={runs}
                onRow={onRow}
            />
        </>
    );
};

DepopRuns.propTypes = {
    depop: PropTypes.object,
};

DepopRuns.defaultProps = {
    depop: undefined,
};

export default DepopRuns;
