import React from 'react';
import ProtectedRoutes from '../components/security';
import { Roles } from '../models';

import PublicRoutes from './public';
import AdminRoutes from './admin';
import Certificate from './public/certificate';
import AuthRoutes from './auth';

export const RootRoutesConfig = [
    {
        path: [
            '/profile', 
            '/dashboard', 
            '/depops',
            '/unassigned-runs',
            '/runs', 
            '/clients',
            '/machines',
        ],
        exact: false,
        main: AdminRoutes,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    {
        path: '/certificate/:id/:token?',
        exact: true,
        main: Certificate,
        access: Roles.ALL,
    },
    
    {
        path: '/validate/:token?',
        exact: true,
        main: AuthRoutes,
        access: Roles.ALL,
    },
    { 
        path: '*',
        main: PublicRoutes,
        access: Roles.ALL,
    },
];

const RootRoutes = () => (
    <ProtectedRoutes routes={RootRoutesConfig} /> 
);

export default RootRoutes;
