import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { Item } from '../../../components/form';

class DepopMetrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metrics: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { metrics } = props;
        if (!Object.equals(metrics, state.metrics)) {
            return { metrics };
        }
        return null;
    }

    render() {
        const { metrics } = this.state;
        return (
            <Form
                className="readonly"
                layout="horizontal"

            >
                <Item label="Runs in depop">{metrics.count || 'N/A'}</Item>
                <Item label="Total operating time">{metrics.totalOperatingTime || 'N/A'}</Item>
                <Item label="Processed hens">{metrics.processedHens || 'N/A'}</Item>
                <Item label="Total CO2">{metrics.totalCO2 || 'N/A'}</Item>
                <Item label="CO2 per hen">{metrics.co2PerHen || 'N/A'}</Item>
            </Form>
        );
    }
}

DepopMetrics.propTypes = {
    metrics: PropTypes.object.isRequired,
};

export default DepopMetrics;
