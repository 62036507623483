import './client.scss';
import React, { Component } from 'react';
import { Button, Row, Col, Icon, Menu } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Roles } from '../../../models';
import { ClientActions as actions } from '../../../actions';
import { history } from '../../../helpers';
import ClientSettings from './client.settings';
import ClientGeneral from './client.general';
import AvatarUpload from '../../../components/form/fields/AvatarUpload';
import ProtectedRoutes, { Secured } from '../../../components/security';

const ClientRoutesConfig = [
    
    { 
        path: '/clients/:id/settings',
        exact: true,
        main: ClientSettings,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
    { 
        path: '/clients/:id',
        exact: true,
        main: ClientGeneral,
        access: [Roles.ADMIN, Roles.CLIENT],
    },
];

const BackForAdmin = ({ id }) => (
    <Button
        type="link back"
        onClick={() => history.push(`/clients/preview/${id}`)}
    >
        <Icon type="left-circle" theme="filled" />
        Back to All Clients
    </Button>
);

BackForAdmin.propTypes = {
    id: PropTypes.string.isRequired
};

const BackForClient = () => (
    <Button
        type="link back"
        onClick={() => history.push('/runs')}
    >
        <Icon type="left-circle" theme="filled" />
        Back
    </Button>
);

class ClientRoute extends Component {
    constructor(props) {
        super(props);
        this.state = { data: {} };
    }

    componentDidMount() {
        const { params: { id } } = this.props;
        actions.get(id)
            .then(data => this.setState({ data }));
    }
    
    render() {
        const { data } = this.state;
        const { params: { id } } = this.props;
        const { location: { pathname } } = history;
        
        const arr = pathname.split('/');
        let path = arr[arr.length - 1];
        path = ['settings', 'statistics'].indexOf(path) > -1 ? path : 'details';
        
        return (
            <>
                <div className="tool-bar">
                    <div className="container">
                        <Secured grant="ADMIN">
                            <BackForAdmin id={id} />
                        </Secured>
                        <Secured grant="CLIENT">
                            <BackForClient id={id} />
                        </Secured>
                    </div>
                </div>
                <div className="container client-details">
                    <Row className="submenu">
                        <Col xs={24} sm={12} md={14}>
                            <Col xs={10} sm={14} md={5} lg={3}>
                                <AvatarUpload data={data} clientId={id} />
                            </Col>
                            <Col xs={14} sm={14} md={19} lg={21}>
                                <h1>{data.name}</h1>
                            </Col>
                        </Col>
                        <Col xs={24} sm={12} md={10}>
                            <Menu selectedKeys={path} mode="horizontal">
                                <Menu.Item key="details">
                                    <Link to={`/clients/${id}`}>
                                        General
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="settings">
                                    <Link to={`/clients/${id}/settings`}>
                                        Client Settings
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="statistics">
                                    <Link to={`/clients/${id}/statistics`} disabled>
                                        Statistics
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        </Col>
                    </Row>
                    <ProtectedRoutes routes={ClientRoutesConfig} />
                </div>
            </>
        );
    }
}

ClientRoute.propTypes = {
    params: PropTypes.object.isRequired
};

export default ClientRoute;
