import React from 'react';
import { Button, Divider, Form, Icon, Input, Spin } from 'antd';
import PropTypes from 'prop-types';
import { FarmActions as actions } from '../../../actions';

class FarmControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
        };
    }

    componentDidMount() {
        const { clientId } = this.props;
        this.setState({ loading: true });
        actions.list(clientId)
            .then(data => this.setState({ data, loading: false }));
    }

    add = () => {
        const { data } = this.state;
        data.push({ id: (data.length + 1) * -1, name: ' ', });
        this.setState({ data });
    };

    remove = (id) => {
        const { clientId } = this.props;
        const { data } = this.state;
        const newData = data.filter(value => value.id !== id);
        actions.remove(clientId, id)
            .then(this.setState({ data: newData }));
    };

    handleSubmit = (e) => {
        const { clientId, form } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                let allPromises = [];
                const farmsToInsert = Object.keys(values)
                    .filter(item => item < 0);
                const farmsToUpdate = Object.keys(values)
                    .filter(item => item > 0);

                const promiseUpdate = farmsToUpdate.map(item =>
                    actions.update(clientId, item, { name: values[item] }));
                const promiseCreate = farmsToInsert.map(item =>
                    actions.create(clientId, { name: values[item] }));
                allPromises = [...promiseUpdate, ...promiseCreate];
                Promise.all(allPromises).then(() => actions.list(clientId)
                    .then(data => this.setState({ data })))
                    .finally(() => this.setState({ loading: false }));
            }
        });
    };

    render() {
        const { form } = this.props;
        const { data, loading } = this.state;
        const { getFieldDecorator } = form;

        const formItems = data.map(farm => (
            <Form.Item
                key={farm.id}
            >
                {getFieldDecorator(`${farm.id}`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: farm.name,
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: "Please input farm's name or delete this field.",
                        },
                    ],
                })(<Input style={{ width: '85%', marginRight: 10 }} />)}
                {data.length > 1 ? (
                    <Icon
                        className="delete"
                        type="close-circle"
                        theme="filled"
                        onClick={() => this.remove(farm.id)}
                    />
                ) : null}
            </Form.Item>
        ));

        return (
            <Spin spinning={loading}>
                <Form
                    onBlur={this.handleSubmit}
                >
                    <h3>Assigned Farms</h3>
                    <Divider style={{ marginBottom: 15 }} />
                    {formItems}
                    <Form.Item>
                        <Button type="primary right" onClick={this.add}>
                            <Icon type="plus" /> Add Farm
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        );
    }
}

FarmControl.propTypes = {
    getFieldDecorator: PropTypes.func,
    form: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
};

FarmControl.defaultProps = {
    getFieldDecorator: () => {
    },
};

export default Form.create({ name: 'dynamic_form_item' })(FarmControl);
