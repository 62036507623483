import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

const Item = ({ label, children }) => (
    <Form.Item
        labelCol={{ span: 12 }}
        label={label}
    >
        {children}
    </Form.Item>
);

Item.propTypes = {
    label: PropTypes.any.isRequired,
    children: PropTypes.any
};

Item.defaultProps = {
    children: null
};

export default Item;
